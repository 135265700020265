import {
    IonFab, IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonIcon, IonPopover, IonList, IonListHeader, IonItem,
    IonFooter, IonSelectOption, IonSelect, IonLabel, IonCol, IonRow, IonText, IonGrid,
} from '@ionic/react';
import { add, personOutline } from 'ionicons/icons';
import React, {useContext, useState,} from 'react';
import { RouteComponentProps } from 'react-router';
import './Home.css';
import {AuthContext} from "../auth";
import {IonButton, IonButtons } from '@ionic/react';
import {MyMap} from "../components/MyMap";

import {getLogger} from "../core";
import {AlertContext} from "../core/AlertProvider";
import Alert from '../components/Alert';
const log = getLogger('Home');

const Home: React.FC<RouteComponentProps> = ({ history }) => {
    const { logout, username } = useContext(AuthContext);
    //const[coordinates, setCoordinates] = useState<[number,number][]>([]);
    let coordinates: [number, number][]= [];
    const [filterAlerts, setFilterAlerts] = useState('alerts');
    const { nearbyAlerts, fetching, fetchingError, c_lat, c_lng, maxDistance, setDistance, resetAlertState} = useContext(AlertContext);
    const [popoverState, setShowUserPopover] = useState({ showUserPopover: false, event: undefined });
    const handleLogOut = () => { logout?.(); resetAlertState?.();};
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle color="success"> Home </IonTitle>
            <IonButtons slot="end">
                {/*<IonButton color="medium" onClick={() => history.push('/leaderboard')}> Leaderboard </IonButton>*/}
                <IonPopover
                    event={popoverState.event}
                    isOpen={popoverState.showUserPopover}
                    onDidDismiss={() => setShowUserPopover({ showUserPopover: false, event: undefined })}
                >
                    <IonList>
                        <IonListHeader><b>User options</b></IonListHeader>
                        <IonItem button onClick={() => history.push('/profile')}>My profile</IonItem>
                        <IonItem button onClick={() => history.push('/myAlerts')}>My alerts/solutions</IonItem>
                        <IonItem button onClick={() => history.push('/rules')}>Rules</IonItem>
                        <IonItem button onClick={handleLogOut} className="logout-button">
                            Log out
                        </IonItem>
                    </IonList>
                </IonPopover>
                <IonButton color="primary" onClick={
                    (e: any) => {
                        e.persist();
                        setShowUserPopover({ showUserPopover: true, event: e })
                    }}> <IonIcon icon={personOutline}/>
                    &nbsp; {username}</IonButton>

            </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen className="ion-padding">
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">GreenDefender</IonTitle>
          </IonToolbar>
        </IonHeader>

          <div className="container ion-padding">
              <strong>Welcome <IonText color="success">{username}</IonText> !</strong>
          </div>
          <IonGrid>
              <IonRow className="ion-align-items-center">
                  <IonCol className="ion-text-center">
                      <IonButton  shape="round" size="small"
                                 onClick={() => history.push('/leaderboard')}>
                          Leaderboard
                      </IonButton>
                  </IonCol>
              </IonRow>
          </IonGrid>

          <IonFab vertical="bottom" horizontal="end" slot="fixed" className="ion-margin-end">
              <IonButton color="success" onClick={() => history.push('/alertAdd')}>
                  <IonIcon icon={add} />
                  Add alert
              </IonButton>
          </IonFab>

          <p className="ion-margin-start">You can find the alerts near you below...</p>
          <p className="ion-text-md-wrap ion-margin-start">
            <IonText color="medium">Number of alerts in the selected area: {nearbyAlerts?.length}</IonText> <br/>
            <IonText color="medium">Number of alerts in the selected area with solutions: {nearbyAlerts?.filter(a => a.isSolved).length}</IonText>
          </p>
          <IonItem>
              <IonLabel>Maximum distance</IonLabel>
              <IonSelect value={maxDistance} okText="Okay" cancelText="Cancel" onIonChange={e => setDistance!(e.detail.value)}>
                  <IonSelectOption value={1}>1 km</IonSelectOption>
                  <IonSelectOption value={5}>5 km</IonSelectOption>
                  <IonSelectOption value={10}>10 km</IonSelectOption>
                  <IonSelectOption value={20}>20 km</IonSelectOption>
                  {username !== 'GreenAdmin' &&
                      <IonSelectOption value={50}>50 km</IonSelectOption>
                  }
                  {username === 'GreenAdmin' &&
                      <IonSelectOption value={420}>420 km</IonSelectOption>
                  }
              </IonSelect>
          </IonItem>

          {(!c_lat || !c_lng) &&
              <div> <p> Searching your GPS location...  </p>
                  <p> <IonText color="medium">Make sure the location is 'on' in your device settings.</IonText> </p>
                  <p> <IonText color="medium">Restart the app if it takes more than 1 minute.</IonText> </p>
              </div>
          }
          {c_lat && c_lng && coordinates && nearbyAlerts && !fetching &&
              <MyMap
                  c_lat={c_lat}
                  c_lng={c_lng}
                  centerUser={true}
                  coordinates={coordinates}
                  zoom={maxDistance===10 ? 12 : (maxDistance===5 ? 13 : (maxDistance===1 ? 15 : (maxDistance===20 ? 11 : 9)))}
                  onMapClick={(e: google.maps.MapMouseEvent) => {
                      log('onMapHome');
                      console.log({latitude: e.latLng!.toJSON().lat, longitude: e.latLng!.toJSON().lng});
                  }
                  }
                  onMarkerClick={(e: google.maps.MapMouseEvent) => {
                      log('onMarker');
                      let id = nearbyAlerts.find(a => a.location?.latitude === e.latLng!.toJSON().lat && a.location?.longitude === e.latLng!.toJSON().lng)?.id;
                      if(id) history.push(`/alertVoteSolve/${id}`);
                  }
                  }
              />
          }
          {c_lat && c_lng && fetching && <MyMap c_lat={c_lat} c_lng={c_lng} zoom={maxDistance===10 ? 12 : (maxDistance===5 ? 13 : (maxDistance===1 ? 15 : (maxDistance===20 ? 11 : 9)))}/>}
          <p> </p>

          <IonItem className="ion-margin-bottom">
              <IonLabel> Show </IonLabel>
              <IonSelect value={filterAlerts} okText="Okay" cancelText="Cancel" onIonChange={e => setFilterAlerts!(e.detail.value)}>
                  <IonSelectOption value={'alerts'}>alerts</IonSelectOption>
                  <IonSelectOption value={'solutions'}>solutions</IonSelectOption>
              </IonSelect>
          </IonItem>
          {fetching &&
                 <p className="ion-text-md-wrap ion-margin-start"> <IonText color="medium" >Fetching nearby alerts...</IonText> <br/> <br/>
                        <IonText color="medium">To manually refresh the nearby alerts, please select another maximum distance.</IonText>
                 </p> }
          {nearbyAlerts && c_lat && c_lng && (
              <IonList color="primary">
                  {nearbyAlerts
                      .filter((a) => {
                          if(filterAlerts === 'solutions') return a.isSolved;
                          else return true;
                      })
                      .sort((a,b) => {        // keep them sorted
                          if(filterAlerts === 'solutions' && a.solutionScore! < b.solutionScore! || filterAlerts === 'alerts' && a.alertScore < b.alertScore)
                              return 1;
                          if(filterAlerts === 'solutions' && a.solutionScore! > b.solutionScore! || filterAlerts === 'alerts' && a.alertScore > b.alertScore)
                              return -1;
                          return 0;
                      })
                      .map((alert) =>
                          {
                              if(alert.alertScore >= -10) coordinates.push([alert.location!.latitude!,alert.location!.longitude!]);
                              return (
                                      <Alert key={alert.id}   id={alert.id}
                                                         title={alert.title}
                                                   description={alert.description}
                                                      category={alert.category}
                                                 reportedPhoto={alert.reportedPhoto}
                                                   solverPhoto={alert.solverPhoto}
                                                      isSolved={alert.isSolved}
                                                      location={alert.location}
                                                        userId={alert.userId}
                                                      solverId={alert.solverId}
                                                    alertScore={alert.alertScore}
                                                 solutionScore={alert.solutionScore}
                                             solverDescription={alert.solverDescription}
                                               currentLocation={{latitude: c_lat, longitude: c_lng}}
                                                      selected={filterAlerts}
                                                        onEdit={id => history.push(`/alertVoteSolve/${id}`)}  />
                                  );
                          }
                      )
                  }
              </IonList>
          )}
          {fetchingError && (
              <div><p>{'Failed to fetch nearby alerts. Please try again. ' + fetchingError.message}</p></div>
          )}


          <IonRow className="ion-align-items-center">
              <IonCol className="ion-text-center">
                  <IonFooter className="ion-padding">
                      <p> </p>
                      <i>Earth provides enough to satisfy every man’s needs, but not every man’s greed.</i>
                      <p> </p>
                  </IonFooter>
              </IonCol>
          </IonRow>
      </IonContent>
    </IonPage>
  );
};

export default Home;

import React, { useContext, useState } from 'react';
import {
    IonButton, IonButtons, IonLabel, IonItem, IonContent, IonHeader, IonInput, IonPage, IonTitle, IonToolbar, IonCol,
    IonIcon, IonRow, IonGrid, IonItemDivider, useIonAlert, IonLoading
} from '@ionic/react';
import './Home.css';
import { getLogger } from '../core';
import { RouteComponentProps } from 'react-router';
import {copy} from "ionicons/icons";
import {AuthContext} from "../auth";
import {getInvitation} from "../auth/authApi";
import {Storage} from "@capacitor/storage";
import {Clipboard} from "@capacitor/clipboard";

const log = getLogger('ProfilePage');

const ProfilePage: React.FC<RouteComponentProps> = ({ history }) => {
    const { token, username, reputationScore, changePassword, removeAccount } = useContext(AuthContext);
    const [generating, setGenerating] = useState(false);
    const [pass, setPass] = useState('');
    const [newPass, setNewPass] = useState('');
    const [newPass2, setNewPass2] = useState('');
    const [generatedInvitation, setGeneratedInvitation] = useState('');
    const [shownReputation, setShownReputation] = useState(reputationScore);

    const [present] = useIonAlert();
    Storage.get({ key: 'reputationScore' }).then(res => setShownReputation(res.value? +res.value : reputationScore));

    const handleGenerateInvitation = () => {
        log('generateInvitation');
        generateInvitationAsync().then();
        async function generateInvitationAsync() {
            try {
                log('sending invitation request...');
                setGenerating(true);
                let invitation_response = await getInvitation(token);
                setGeneratedInvitation(invitation_response.invitation);
                setGenerating(false);
                log('Invitation generated: ' + invitation_response.invitation);
            } catch (error: any) {
                log('invitation request failed');
                setGenerating(false);
                if (!error.response) {
                    present({
                        message: 'Please check your internet connection...',
                        buttons: ['Ok']
                    });
                }
                else {
                    present({
                        message: error.response.data.message || 'Invitation request failed',
                        buttons: ['Ok']
                    });
                }
            }
        }
    }

    const handlePassChange = () => {
        if(pass === '' || newPass === '' || newPass2 === ''){
            present({
                header: 'Incomplete fields',
                message: 'All password fields must be filled.',
                buttons: [{ text: 'Ok'}]
            })
            return;
        }
        if(newPass !== newPass2){
            present({
                header: 'New password mismatch',
                message: 'You did not type in the same new password twice.',
                buttons: [{ text: 'Ok'}]
            })
            return;
        }
        changePassword && changePassword(pass, newPass);
    };

    const handleDeleteAccount = () => {
        if(pass === ''){
            present({
                header: 'Current password needed',
                message: 'Please type in your current password in the above respective field to continue.',
                buttons: [{ text: 'Ok'}]
            })
            return;
        }
        present({
            header: 'Confirm account deletion',
            message: 'All your progress will be lost (reputation score, alerts and solutions).',
            cssClass:'logout-button',
            buttons: [
                'Cancel',
                { text: 'DELETE ACCOUNT',
                    handler: () => {
                        removeAccount && removeAccount(username, pass);
                    }
                },
            ]
        })

    };
    log('render');
    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonTitle>Profile</IonTitle>
                    <IonButtons slot="end">
                        <IonButton onClick={() =>  history.goBack()}>
                            Back
                        </IonButton>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>

            <IonContent className="ion-padding">
                <IonGrid>
                    <IonRow className="ion-padding">
                        <IonCol> <IonLabel color="success">Username:</IonLabel> </IonCol>
                        <IonCol> <IonLabel> {username} </IonLabel> </IonCol>
                    </IonRow>
                    <IonRow className="ion-padding-start">
                        <IonCol> <IonLabel color="success">Reputation score:</IonLabel> </IonCol>
                        <IonCol> <IonLabel> {shownReputation} </IonLabel> </IonCol>
                    </IonRow>
                    <IonItemDivider/>

                    <IonRow className="ion-padding-start ion-padding-top ion-align-items-baseline">
                        <IonCol size="4.5"> <IonLabel color="primary">Current password</IonLabel> </IonCol>
                        <IonCol><IonItem>
                            <IonInput value={pass} placeholder="password" type="password"
                                      onIonChange={e => setPass(e.detail.value || '')}/>
                        </IonItem>
                        </IonCol>
                    </IonRow>
                    <IonRow className="ion-padding-start ion-align-items-baseline">
                        <IonCol size="4.5" className="ion-justify-content-center ion-align-items-center"> <IonLabel color="primary">New password</IonLabel> </IonCol>
                        <IonCol> <IonItem> <IonInput value={newPass} placeholder="password" type="password"
                                                     onIonChange={e => setNewPass(e.detail.value || '')}/>
                        </IonItem> </IonCol>
                    </IonRow>
                    <IonRow className="ion-padding-start ion-align-items-baseline">
                        <IonCol size="4.5" className="ion-justify-content-center ion-align-items-center"> <IonLabel color="primary">Re-type new password</IonLabel> </IonCol>
                        <IonCol> <IonItem> <IonInput value={newPass2} placeholder="password" type="password"
                                                     onIonChange={e => setNewPass2(e.detail.value || '')}/>
                        </IonItem> </IonCol>
                    </IonRow>
                    <IonRow className="ion-padding-start ion-align-items-baseline">
                        <IonCol>
                            <IonButton onClick={handlePassChange}>
                                Change password
                            </IonButton>
                        </IonCol>
                    </IonRow>
                    <IonItemDivider/>

                    <IonRow className="ion-padding">
                        <IonCol>Important notice: if an invited user is banned, your reputation will be lowered.</IonCol>
                    </IonRow>

                    <IonRow className="ion-padding-start ion-margin-bottom">
                        {(reputationScore > 0 || username==='GreenAdmin') &&
                            <IonButton color="primary" onClick={handleGenerateInvitation}>
                                Generate invitation
                            </IonButton>
                        }
                        {reputationScore <= 0 && username!=='GreenAdmin' && <IonLabel color="medium"> Positive reputation is needed to generate invitations. </IonLabel>}
                    </IonRow>

                    <IonRow className="ion-padding-start ion-align-items-center">
                        <IonCol size="9.5"> <IonItem> <IonInput value={generatedInvitation} placeholder="invitation code" readonly> </IonInput> </IonItem> </IonCol>
                        <IonCol>
                            <IonButton size="small" color="medium" shape="round"
                                           onClick={()=>Clipboard.write({string: generatedInvitation || ''})}>
                                        <IonIcon slot="icon-only" icon={copy} />
                            </IonButton>
                        </IonCol>
                    </IonRow>
                    <IonItemDivider/>

                    {username!=='GreenAdmin' &&
                        <div>
                            <IonRow className="ion-padding-start ion-align-items-baseline ion-padding-top">
                                <IonCol>
                                    <IonButton color="danger" onClick={handleDeleteAccount}>
                                        Delete account
                                    </IonButton>
                                </IonCol>
                            </IonRow>
                            <IonItemDivider/>
                        </div>
                    }

                    {username==='GreenAdmin' &&
                        <IonRow className="ion-padding-start ion-align-items-baseline ion-padding-top">
                            <IonCol>
                                <IonButton color="success" onClick={() => history.push('adminPanel')}>
                                    Admin panel
                                </IonButton>
                            </IonCol>
                        </IonRow> }
                </IonGrid>

                <IonLoading isOpen={generating} />
            </IonContent>
        </IonPage>
    );

}

export default ProfilePage;
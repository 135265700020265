import './ExploreContainer.css';
import {IonButton, IonTitle, useIonAlert} from '@ionic/react';
import React, {useContext, useEffect, useState} from "react";
import {Storage} from "@capacitor/storage";
import {AuthContext} from "../auth";

interface ContainerProps { }

const ExploreContainer: React.FC<ContainerProps> = () => {
    const { isAuthenticated, token } = useContext(AuthContext);
    const [username, setUsername] = useState('');
    useEffect(() => {Storage.get({ key: 'username' }).then(r => setUsername(r.value!))}, [token, isAuthenticated]);
    const [present] = useIonAlert();
    return (
    <div className="container ion-padding">
        {/*<IonTitle className="ion-padding">User: {username} </IonTitle>*/}
      <strong>Welcome to <i>GreenDefender</i> !</strong>
        <p className="ion-padding-bottom"> The community-based, cross-platform application for environmental alerts. </p>
        <p>Start reporting any nature related problem by adding photo evidence and pinpointing it on the map!
          <a target="_blank" rel="noopener noreferrer" href="/"> </a></p>

            {/*<IonButton  className="ion-padding"*/}
            {/*    // expand="block"*/}
            {/*    onClick={() => present('Hitman-ul de drujbari a fost trimis in misiune.', [{ text: 'Ok' }])}*/}
            {/*>*/}
            {/*    Fast solution*/}
            {/*</IonButton>*/}

    </div>
    );
};

export default ExploreContainer;

import axios from 'axios';
import {authConfig, http, baseUrl, config, withLogs} from '../core';
import {UserProps} from "./UserProps";

const url = `${http}://${baseUrl}/api/user`;

export interface UsersResponseProps {
  noOfAlerts?: number;
  users: UserProps[];
}

export const login: (username?: string, password?: string) => Promise<UserProps> = (username, password) => {
  return withLogs(axios.post(`${url}/login`, { username, password }, config), 'login');
}

export const signup: (username?: string, password?: string, invitation?: string) => Promise<UserProps> = (username, password, invitation) => {
  return withLogs(axios.post(`${url}/new`, { username, password, invitation }, config), 'signup');
}

export const getInvitation: (token: string) => Promise<any> = (token) => {                      // {"invitation": "string"}
  return withLogs(axios.get(`${url}/invitation`, authConfig(token)), 'getInvitation');
}

export const changePassword: (token: string, username: string, password: string, newPassword: string) => Promise<any> = (token, username, password, newPassword) => {
  return withLogs(axios.put(`${url}/password`, { username, password, newPassword }, authConfig(token)), 'updatePassword');
}

export const deleteUser: (token: string, username?: string, password?: string) => Promise<any> = (token, username, password) => {
  return withLogs(axios.post(`${url}/delete`, { username, password }, authConfig(token)), 'deleteUser');
}

export const getTopUsers: () => Promise<UsersResponseProps> = () => {
  return withLogs(axios.get(`${url}/topUsers`, config), 'getTop100Users');
}

export const getAllUsersAdmin: (token: string) => Promise<UsersResponseProps> = (token) => {
  return withLogs(axios.get(`${url}/all`, authConfig(token)), 'getAllUsersAdmin');
}

import React, { useContext, useEffect, useState } from 'react';
import {
    IonButton, IonButtons, IonLabel, IonItem, IonContent, IonHeader, IonInput, IonPage, IonTitle, IonToolbar,
    IonLoading, IonCol, IonImg, IonIcon, IonActionSheet, IonTextarea, IonRadioGroup, IonRadio, IonRow, IonText,
    IonItemDivider, useIonAlert
} from '@ionic/react';
import { getLogger, round } from '../core';
import { RouteComponentProps } from 'react-router';
import {MyMap} from "../components/MyMap";
import {usePhotoCamera} from "../core/usePhotoCamera";
import {AlertProps} from "../core/AlertProps";
import {camera, trash, close, caretUpOutline, caretDownOutline, copy} from "ionicons/icons";
import {AlertContext} from "../core/AlertProvider";
import {AuthContext} from "../auth";
import {Clipboard} from "@capacitor/clipboard";

const log = getLogger('AlertVoteSolve');

interface AlertEditProps extends RouteComponentProps<{
    id?: string;
}> {}

const AlertVoteSolve: React.FC<AlertEditProps> = ({ history, match }) => {
    const {currentUserId} = useContext(AuthContext);
    const { nearbyAlerts, saving, c_lat: lat, c_lng: lng, maxDistance, vote: sendVote, solveAlert } = useContext(AlertContext);
    const [solverPhoto, setSolverPhoto] = useState<string|undefined>(undefined);
    const [isSolved, setIsSolved] = useState<boolean>(false);
    const [location, setLocation] = useState<{latitude:number|undefined, longitude:number|undefined}>({latitude:0, longitude:0});
    const [solverDescription, setSolverDescription] = useState('');
    const [alert, setAlert] = useState<AlertProps>();
    const { takePhoto, deletePhoto } = usePhotoCamera();
    const [photoToDelete, setPhotoToDelete] = useState<string>();
    const [present] = useIonAlert();

    useEffect(() => {
        log('useEffect');
        let cancel = false;
        const routeId = match.params.id || '';
        const _alert = nearbyAlerts?.find(a => a.id === routeId);
        if (_alert) {
            if (cancel) return;
            setAlert(_alert);
            setLocation(_alert.location!);
            setSolverPhoto(_alert.solverPhoto);
            setIsSolved(_alert.isSolved);
            setSolverDescription(_alert.solverDescription || '');
        }
        return () => {
            // This is the cleanup function
            cancel = true;
        }
    }, [match.params.id, nearbyAlerts]);

    const handleSubmit = () => {                              // adds the solution
        if(solverDescription && solverPhoto){
            if(isSolved)
                present({
                    header: 'Confirm solution locking',
                    message: 'You have set the solution status to "solved". This will enable the voting and the scoring of your solution.',
                    buttons: [
                        'Cancel',
                        { text: 'Confirm',
                            handler: () => {
                                const solvedAlert = { ...alert!, solverPhoto, isSolved, solverDescription, solverId: currentUserId };
                                solveAlert && solveAlert(solvedAlert).then(() => history.goBack());
                            }
                        },
                    ]
                });
            else {
                const solvedAlert = { ...alert!, solverPhoto, isSolved, solverDescription, solverId: currentUserId };
                solveAlert && solveAlert(solvedAlert).then(() => history.goBack());
            }

        }
        else present({
                header: 'Incomplete solution',
                message: 'All solution fields must be filled: photo, description.',
                buttons: ['Ok']
            });
    };

    const handleAlertVote = (vote: string) => {
        present({
            header: 'Confirm vote',
            message: 'The vote cannot be undone and modifies various reputation scores.',
            buttons: [
                'Cancel',
                { text: 'VOTE',
                    handler: () => {
                        const votedAlert = { ...alert!, currentUserVote: vote };
                        sendVote?.(votedAlert);
                    }
                },
            ]
        });
    };

    const handleSolutionVote = (vote: string) => {
        present({
            header: 'Confirm vote',
            message: 'The vote cannot be undone and modifies various reputation scores.',
            buttons: [
                'Cancel',
                { text: 'VOTE',
                    handler: () => {
                        const votedSolution = {...alert!, currentUserSolutionVote: vote};
                        sendVote?.(votedSolution);
                    }
                },
            ]
        });
    };

    log('render');
    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonTitle>Alert <IonText color="success">{alert?.id}</IonText></IonTitle>
                    <IonButtons slot="end">
                        {/*To submit solution: upvote alert/alert is yours, and another locked solution does not exist.*/}
                        {(alert?.currentUserVote === 'upvote' || alert?.userId === currentUserId) && !alert.isSolved &&
                            <IonButton onClick={handleSubmit} color="success">
                                Submit solution
                            </IonButton>
                        }
                        <IonButton onClick={() => history.goBack()}>
                            Back
                        </IonButton>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>

            <IonContent className="ion-padding">
                <IonItem>
                    <IonLabel color="danger">Title:</IonLabel>
                    <IonInput value={alert?.title} readonly/>
                </IonItem>
                <IonItem>
                    <IonLabel color="danger">Description:</IonLabel>
                    <IonTextarea value={alert?.description} readonly/>
                </IonItem>

                <IonItem>
                    <IonLabel color="danger">Reported photo:</IonLabel>
                    {alert?.reportedPhoto && <IonCol size="4">
                        <IonImg src={alert?.reportedPhoto} onClick={() =>
                            present({
                                cssClass: 'popup',
                                header: 'Reported photo',
                                message: `<img src="${alert?.reportedPhoto}" alt="r-img" style="border-radius: 2px">`,
                            })
                        }/>
                    </IonCol>}
                </IonItem>

                <IonItem>
                    <IonLabel color="danger">Category:</IonLabel>
                    <IonLabel className="ion-text-right"> {alert?.category} </IonLabel>
                </IonItem>

                <IonItem>
                    <IonLabel color="danger">GPS Location:</IonLabel>
                </IonItem>
                <IonItem>
                    <IonCol>
                        lat { location.latitude===0 ? round(lat!,10) : round(location.latitude!,10)}
                    </IonCol>
                    <IonCol>
                        long {location.longitude===0 ? round(lng!,10) : round(location.longitude!,10)}
                    </IonCol>
                    <IonCol>
                        <IonButton size="small" color="medium" shape="round"
                                   onClick={()=>Clipboard.write({string:
                                           ((location.latitude===0 ? lat : location.latitude) + ', ' + (location.longitude===0 ? lng : location.longitude))})}>
                            <IonIcon slot="icon-only" icon={copy} />
                        </IonButton>
                    </IonCol>
                </IonItem>

                {lat && lng && location &&
                <MyMap
                    c_lat={lat}
                    c_lng={lng}
                    zoom={maxDistance===10 ? 12 : (maxDistance===5 ? 13 : (maxDistance===1 ? 15 : (maxDistance===20 ? 11 : 9)))}
                    centerUser={false}
                    coordinates={ location.latitude===0 ? undefined : [[location.latitude,location.longitude]]}
                    onMapClick={(e: google.maps.MapMouseEvent) => {
                        log('onMap');
                        console.log({latitude: e.latLng!.toJSON().lat, longitude: e.latLng!.toJSON().lng});
                       }
                    }
                    onMarkerClick={undefined}
                /> }

                {(!lat || !lng) &&
                    <p> Searching your GPS location... </p>
                }

                <IonItem className="ion-margin-top">
                    <IonLabel color="danger">Alert score:</IonLabel>
                    <IonInput value={alert?.alertScore} readonly/>
                </IonItem>

                <IonItem>
                    <IonText>
                        <IonText color="danger">
                            Your alert vote:
                        </IonText>
                        <IonText>
                            {!alert?.currentUserVote && <i>  no vote </i>}
                            {currentUserId === alert?.userId && <b> (your alert) </b>}
                            {alert?.currentUserVote && <i> {alert.currentUserVote} </i>}
                        </IonText>
                    </IonText>
                    {/*To vote alert: you did not vote for this alert before, there's no locked solution, and it's not your alert.*/}
                    {!alert?.currentUserVote && !alert?.isSolved && currentUserId !== alert?.userId &&
                        <IonCol className="ion-text-right">
                            <IonButton shape="round" color="success" onClick={() => handleAlertVote('upvote')}>
                                <IonIcon slot="start" icon={caretUpOutline}/>
                                <b> Up-vote </b>
                            </IonButton>
                            <IonText> </IonText>
                            <IonButton shape="round" color="danger" onClick={() => handleAlertVote('downvote')}>
                                <IonIcon slot="end" icon={caretDownOutline}/>
                                <b> Down-vote </b>
                            </IonButton>
                        </IonCol>
                    }
                    {(alert?.currentUserVote || currentUserId === alert?.userId || alert?.isSolved) &&
                        <IonCol className="ion-text-right">
                            <IonButton shape="round" color="success" disabled>
                                <IonIcon slot="start" icon={caretUpOutline}/>
                                <b> Up-vote </b>
                            </IonButton>
                            <IonText> </IonText>
                            <IonButton shape="round" color="danger" disabled>
                                <IonIcon slot="end" icon={caretDownOutline}/>
                                <b> Down-vote </b>
                            </IonButton>
                        </IonCol>
                    }
                </IonItem>


                {/*In the next section you can: submit solution or vote existing solution.*/}
                {/*To submit solution: upvote alert/alert is yours, and another locked solution does not exist.*/}
                {/*To vote solution: a locked solution exists, and the locked solution is not yours.*/}
                {(alert?.currentUserVote === 'upvote' || alert?.userId === currentUserId || alert?.solverId) &&
                    (alert?.currentUserVote !== 'downvote' || alert.isSolved) &&
                    <div>
                        <IonItemDivider/>
                        <p>
                            <IonText color="success">Solution</IonText> {alert.isSolved ? 'locked' : 'unlocked' }
                        </p>

                        <IonItem>
                            <IonLabel color="danger">Solution photo:</IonLabel>
                            {solverPhoto && <IonCol size="4">
                                <IonImg onClick={() => {
                                    setPhotoToDelete(solverPhoto);
                                    present({
                                        header: 'Solution photo',
                                        message: `<img src="${solverPhoto}" alt="r-img" >`,
                                    });
                                }}
                                        src={solverPhoto}/>
                            </IonCol>}
                            {!alert.isSolved &&
                                <IonCol>
                                    <IonButton
                                        onClick={() => takePhoto().then(res => res ? setSolverPhoto(res.data) : undefined)}>
                                        <IonIcon icon={camera}/>
                                    </IonButton>
                                </IonCol>
                            }
                        </IonItem>

                        {!alert.isSolved &&
                            <IonActionSheet
                                isOpen={!!photoToDelete}
                                buttons={[{
                                    text: 'Delete',
                                    role: 'destructive',
                                    icon: trash,
                                    handler: () => {
                                        if (photoToDelete) {
                                            deletePhoto({filepath: '', data: photoToDelete}).then(_ => {
                                                if (solverPhoto && photoToDelete === solverPhoto)
                                                    setSolverPhoto(undefined);
                                                setPhotoToDelete(undefined);
                                            });
                                        }
                                    }
                                }, {
                                    text: 'Cancel',
                                    icon: close,
                                    role: 'cancel'
                                }]}
                                onDidDismiss={() => setPhotoToDelete(undefined)}
                            />
                        }

                        <IonItem>
                            <IonLabel color="danger">Solution description:</IonLabel>
                            {!alert.isSolved &&
                                <IonTextarea placeholder="Describe your solution" value={solverDescription}
                                             onIonChange={e => setSolverDescription(e.detail.value || '')} />
                            }
                            {alert.isSolved &&
                                <IonTextarea value={solverDescription} readonly/>
                            }
                        </IonItem>

                        <IonItem>
                            <IonLabel color="danger">Solved?</IonLabel>
                            {!alert.isSolved &&
                                <IonRadioGroup value={isSolved ? 'Yes' : 'No'} onIonChange={e => {
                                    if (e.detail.value === 'Yes')
                                        setIsSolved(true);
                                    else setIsSolved(false);
                                }}>
                                    <IonRow>
                                        <IonItem className="ion-margin-start">
                                            <IonLabel>Yes</IonLabel>
                                            <IonRadio slot="start" value="Yes"/>
                                        </IonItem>
                                        <IonItem className="ion-margin-end">
                                            <IonLabel>No</IonLabel>
                                            <IonRadio slot="start" value="No"/>
                                        </IonItem>
                                    </IonRow>
                                </IonRadioGroup>
                            }

                            {alert.isSolved &&
                                <IonRadioGroup value={'Yes'}>
                                    <IonRow>
                                        <IonItem className="ion-margin-start">
                                            <IonLabel>Yes</IonLabel>
                                            <IonRadio slot="start" value="Yes"/>
                                        </IonItem>
                                        <IonItem className="ion-margin-end">
                                            <IonLabel>No</IonLabel>
                                            <IonRadio slot="start" value="No" disabled/>
                                        </IonItem>
                                    </IonRow>
                                </IonRadioGroup>
                            }
                        </IonItem>

                        {alert.isSolved &&
                            <IonItem>
                                <IonLabel color="danger">Solution score:</IonLabel>
                                <IonInput value={alert?.solutionScore} readonly/>
                            </IonItem>
                        }

                        { alert.isSolved &&
                            <IonItem>
                                <IonText>
                                    <IonText color="danger">
                                        Your solution vote:
                                    </IonText>
                                    <IonText>
                                        {!alert?.currentUserSolutionVote && <i> no vote </i>}
                                        {currentUserId === alert.solverId && <b> (your solution) </b>}
                                        {alert?.currentUserSolutionVote && <i> {alert.currentUserSolutionVote} </i>}
                                    </IonText>
                                </IonText>
                                {!alert.currentUserSolutionVote && currentUserId !== alert.solverId &&
                                    <IonCol className="ion-text-right">
                                        <IonButton shape="round" color="success"
                                                   onClick={() => handleSolutionVote('upvote')}>
                                            <IonIcon slot="start" icon={caretUpOutline}/>
                                            <b> Up-vote </b>
                                        </IonButton>
                                        <IonText> </IonText>
                                        <IonButton shape="round" color="danger"
                                                   onClick={() => handleSolutionVote('downvote')}>
                                            <IonIcon slot="end" icon={caretDownOutline}/>
                                            <b> Down-vote </b>
                                        </IonButton>
                                    </IonCol>
                                }
                                {(alert.currentUserSolutionVote || currentUserId === alert.solverId) &&
                                    <IonCol className="ion-text-right">
                                        <IonButton shape="round" color="success" disabled>
                                            <IonIcon slot="start" icon={caretUpOutline}/>
                                            <b> Up-vote </b>
                                        </IonButton>
                                        <IonText> </IonText>
                                        <IonButton shape="round" color="danger" disabled>
                                            <IonIcon slot="end" icon={caretDownOutline}/>
                                            <b> Down-vote </b>
                                        </IonButton>
                                    </IonCol>
                                }
                            </IonItem>
                        }
                    </div>
                }

                <IonLoading isOpen={saving} />
                {/*{savingError && (*/}
                {/*    <div className="ion-padding">{savingError.message || 'Failed to solve alert'}</div>*/}
                {/*)}*/}
            </IonContent>
        </IonPage>
    );

}

export default AlertVoteSolve;

/*
if solutionScore:
    if solutionScore < 0:
       solution can be replaced &&
       solution can be voted
    else if solutionScore >= 0:
        solution CAN only be voted
else: solution can be added

=> editable photo/description/solved & NO vote for: (!solutionScore || solutionScore && solutionScore<0)
   not editable and vote for:                       (solutionScore && solutionScore>=0 && isSolved)
 */
import React, {useCallback, useContext, useEffect, useReducer, useState} from 'react';
import PropTypes from 'prop-types';
import { getLogger } from '../core';
import { AlertProps } from './AlertProps';
import {
    createAlert, getNearbyAlerts, getUserAlerts, updateAlert, solveAlert as solveAlertApi, deleteAlert,
    updateSolution, deleteSolution, VoteResponseProps, voteAlert, voteSolution
} from './alertApi';
import { AuthContext } from '../auth';
import { Storage } from '@capacitor/storage';
import { App } from '@capacitor/app';
import {useMyLocation} from "./useMyLocation";
import {getDistanceFromLatLonInKm} from "../components/MyMap";
import {useIonAlert, useIonRouter, useIonToast} from "@ionic/react";
import {useNetwork} from "./useNetwork";
import {SolutionProps} from "./SolutionProps";

const log = getLogger('AlertProvider');

type SaveAlertFn = (alert: AlertProps) => Promise<any>;
type GetAlertsFn = () => Promise<any>;

export interface AlertsState {
    nearbyAlerts?: AlertProps[],
    userAlerts?: AlertProps[],
    fetching: boolean,
    userFetching: boolean,
    fetchingError?: Error | null,
    userFetchingError?: Error | null,
    saving: boolean,
    savingError?: Error | null,
    saveAlert?: SaveAlertFn,
    solveAlert?: SaveAlertFn,
    vote?: SaveAlertFn,
    deleting: boolean,
    deletingError?: Error | null,
    delAlert?: SaveAlertFn,
    getUserAlertsCallback?: GetAlertsFn,

    c_lat?: number,
    c_lng?: number,
    maxDistance: number,
    setDistance?: (d?: number) => void,
    resetAlertState?: () => void,
}

interface ActionProps {
    type: string,
    payload?: any,
}

const initialState: AlertsState = {
    fetching: false,
    userFetching: false,
    saving: false,
    deleting: false,
    maxDistance: 10,
};

const FETCH_ALERTS_STARTED = 'FETCH_ALERTS_STARTED';
const FETCH_USER_ALERTS_STARTED = 'FETCH_USER_ALERTS_STARTED';
const FETCH_ALERTS_SUCCEEDED = 'FETCH_ALERTS_SUCCEEDED';
const FETCH_USER_ALERTS_SUCCEEDED = 'FETCH_USER_ALERTS_SUCCEEDED';
const FETCH_ALERTS_FAILED = 'FETCH_ALERTS_FAILED';
const FETCH_USER_ALERTS_FAILED = 'FETCH_USER_ALERTS_FAILED';
const SAVE_ALERT_STARTED = 'SAVE_ALERT_STARTED';
const SAVE_ALERT_SUCCEEDED = 'SAVE_ALERT_SUCCEEDED';
const SAVE_ALERT_FAILED = 'SAVE_ALERT_FAILED';
const DELETE_ALERT_STARTED = 'DELETE_ALERT_STARTED';
const DELETE_ALERT_SUCCEEDED = 'DELETE_ALERT_SUCCEEDED';
const DELETE_ALERT_FAILED = 'DELETE_ALERT_FAILED';
const SET_DISTANCE = 'SET_DISTANCE';
const RESET_STATE = 'RESET_STATE';

const reducer: (state: AlertsState, action: ActionProps) => AlertsState =
    (state, { type, payload }) => {
        switch (type) {
            case RESET_STATE:
                return initialState;
            case FETCH_ALERTS_STARTED:
                return { ...state, fetching: true, fetchingError: null };
            case FETCH_USER_ALERTS_STARTED:
                return { ...state, userFetching: true, userFetchingError: null };
            case FETCH_ALERTS_SUCCEEDED:
                return { ...state, nearbyAlerts: payload.nearbyAlerts, fetching: false };
            case FETCH_USER_ALERTS_SUCCEEDED:
                //Storage.set({key: 'user_alerts', value: JSON.stringify(payload.userAlerts)}).then();
                return { ...state, userAlerts: payload.userAlerts, userFetching: false };
            case FETCH_ALERTS_FAILED:
                return { ...state, fetchingError: payload.error, fetching: false };
            case FETCH_USER_ALERTS_FAILED:
                return { ...state, userFetchingError: payload.error, userFetching: false };
            case SAVE_ALERT_STARTED:
                return { ...state, savingError: null, saving: true };
            case SAVE_ALERT_SUCCEEDED:
                const nearbyAlerts = [...(state.nearbyAlerts || [])]; // add alerts and spread them - avoid reference
                const userAlerts = [...(state.userAlerts || [])]; // add alerts and spread them - avoid reference
                const alert = payload.alert;
                const index = nearbyAlerts.findIndex(a => a.id === alert.id);
                const index2 = userAlerts.findIndex(a => a.id === alert.id);
                if (index === -1) {
                    let low = 0, high = nearbyAlerts.length;
                    while (low < high) {
                        let mid = (low + high) >>> 1;
                        if (nearbyAlerts[mid].alertScore > alert.alertScore) low = mid + 1;
                        else high = mid;
                    }
                    nearbyAlerts.splice(low, 0, alert);

                } else {
                    nearbyAlerts[index] = alert;
                }
                if (index2 === -1) {
                    userAlerts.splice(0, 0, alert);
                } else {
                    userAlerts[index2] = alert;
                }
                //Storage.set({key: 'user_alerts', value: JSON.stringify(userAlerts)}).then();
                return { ...state, nearbyAlerts, userAlerts, saving: false, deleting: false };
            case SAVE_ALERT_FAILED:
                return { ...state, savingError: payload.error, saving: false };
            case DELETE_ALERT_STARTED:
                return { ...state, deletingError: null, deleting: true };
            case DELETE_ALERT_SUCCEEDED:
            {
                const nearbyAlerts = [...(state.nearbyAlerts || [])];
                const userAlerts = [...(state.userAlerts || [])];
                const deleted_alert = payload.alert;
                const index2 = nearbyAlerts.findIndex(a => a.id === deleted_alert.id);
                if (index2 !== -1)
                    nearbyAlerts.splice(index2, 1);
                const index3 = userAlerts.findIndex(a => a.id === deleted_alert.id);
                if (index3 !== -1)
                    userAlerts.splice(index3, 1);
                //Storage.set({key: 'user_alerts', value: JSON.stringify(userAlerts)}).then();
                return { ...state, nearbyAlerts, userAlerts, deleting: false };
            }
            case DELETE_ALERT_FAILED:
                return { ...state, deletingError: payload.error, deleting: false };
            case SET_DISTANCE:
                if(payload.distance < 0) return state;
                return {...state, maxDistance: payload.distance};
            default:
                return state;
        }
    };

export const AlertContext = React.createContext<AlertsState>(initialState);

interface AlertProviderProps {
    children: PropTypes.ReactNodeLike,
}

export const AlertProvider: React.FC<AlertProviderProps> = ({ children }) => {
    const { networkStatus } = useNetwork();
    const { token, isAuthenticated, logout, currentUserId, updateReputation } = useContext(AuthContext);
    const myLocation = useMyLocation();
    const { latitude: c_lat, longitude: c_lng } = myLocation.position?.coords || {};
    const [prevLocation, setPrevLocation] = useState<{latitude:number|undefined, longitude:number|undefined}>({latitude: 0, longitude: 0});
    const [prevDistance, setPrevDistance] = useState<number>(10);
    const [present] = useIonToast();
    const [presentAlert] = useIonAlert();
    useEffect(retryOfflineOpsEffect, [networkStatus.connected]);                  // OBSERVER - behavioral
    const [state, dispatch] = useReducer(reducer, initialState);
    const { nearbyAlerts, userAlerts, fetching, userFetching, userFetchingError, fetchingError, saving, savingError, deleting, deletingError, maxDistance } = state;
    useEffect(getAlertsEffect, [isAuthenticated, token, maxDistance, c_lat, c_lng]);  // [] = use only once after the component is rendered
    useEffect(getUserAlertsEffect, [isAuthenticated, token]);
    const saveAlert = useCallback<SaveAlertFn>(saveAlertCallback, [token]);
    const delAlert = useCallback<SaveAlertFn>(deleteAlertCallback, [token]);
    const vote = useCallback<SaveAlertFn>(voteCallback, [token]);
    const solveAlert = useCallback<SaveAlertFn>(solveAlertCallback, [token]);
    const setDistance = useCallback<(distance?: number) => void>(setDistanceCallback, []);
    const resetAlertState = useCallback<() => void>(resetStateCallback, []);
    const value = { nearbyAlerts, userAlerts, fetching, userFetching, userFetchingError, fetchingError, saving, savingError,
                    saveAlert, deleting, deletingError, delAlert, c_lat, c_lng, maxDistance, setDistance, vote, solveAlert, resetAlertState };

    // app exit handler
    const ionRouter = useIonRouter();
    document.addEventListener('ionBackButton', (ev) => {
        const customEvent = ev as CustomEvent;
        customEvent.detail.register(-1, () => {
            if (!ionRouter.canGoBack()) {
                presentAlert({
                    header: 'Exit GreenDefender?',
                    //message: 'The information you entered will be lost.',
                    buttons: [
                        'Cancel',
                        { text: 'Exit',
                            handler: () => {App.exitApp();}
                        },
                    ]
                })

            }
        });
    });

    log('returns');
    return (
        <AlertContext.Provider value={value}>
            {children}
        </AlertContext.Provider>
    );

    function setDistanceCallback(distance?: number): void {
        dispatch({ type: SET_DISTANCE, payload: { distance } });
    }

    function resetStateCallback(): void {
        setPrevLocation({latitude: 0, longitude: 0});
        dispatch({ type: RESET_STATE, payload: {} });
    }

    function retryOfflineOpsEffect() {
        if(networkStatus.connected) {
            //retryOfflineOps().then();
        }
        async function retryOfflineOps() {
            const v2 = await Storage.get({key: 'alerts_to_retry'});
            if(!v2.value || saving === true) return;
            let alerts_to_retry: AlertProps[] = JSON.parse(v2.value!);
            await Storage.set({key: 'alerts_to_retry', value: JSON.stringify([])});
            //let alerts_to_retry_next: AlertProps[] = [];
            if(alerts_to_retry.length > 0 && !saving) {
                present({message: 'Uploading alert updates...', duration: 3000, animated: true});
                for(let idx in alerts_to_retry)
                    try{
                        // await (alerts_to_retry[idx].id ?
                        //     (alerts_to_retry[idx].solverPhoto ? solveAlert(token,alerts_to_retry[idx]) : updateAlert(token,alerts_to_retry[idx]))
                        //     : createAlert(token,alerts_to_retry[idx]));
                    }
                    catch(error: any){
                        if (!networkStatus.connected || (error.message && error.message === 'Network Error')){
                            present({message: 'Network error. Uploading failed.', duration: 2000, animated: true});
                            // const i = alerts_to_retry_next.findIndex(a => a.id === alerts_to_retry[idx].id);
                            // if(i && i !== -1) alerts_to_retry_next[i] = alerts_to_retry[idx];
                            // else alerts_to_retry_next.push(alerts_to_retry[idx]);
                        }
                    }
            }
        }
    }

    function getAlertsEffect() {
        let canceled = false;
        fetchAlerts().then();
        return () => {
            canceled = true;
        }
        async function fetchAlerts() {
            //log('fetchAlerts ATTEMPT ------------------');
            //console.log(token,c_lat,c_lng,prevLocation);
            if (!token?.trim() || !c_lat || !c_lng) return;
            if( prevLocation.latitude !== 0 && prevLocation.longitude !== 0 &&
                getDistanceFromLatLonInKm(c_lat,c_lng,prevLocation.latitude!,prevLocation.longitude!)<0.5 && maxDistance === prevDistance)
                return;

            try {
                log('fetchAlerts started');
                dispatch({ type: FETCH_ALERTS_STARTED });
                setPrevDistance(maxDistance);
                setPrevLocation({ latitude: c_lat, longitude: c_lng });
                const alerts_response = await getNearbyAlerts(token, { latitude: c_lat, longitude: c_lng }, maxDistance);
                const nearbyAlerts: AlertProps[] = alerts_response.alerts;
                log('fetchAlerts succeeded');
                if(alerts_response.currentUserReputation && updateReputation) updateReputation(alerts_response.currentUserReputation);
                if (!canceled) {
                    dispatch({ type: FETCH_ALERTS_SUCCEEDED, payload: { nearbyAlerts } });
                }
                else log("fetchAlerts canceled!");
            } catch (error: any) {
                log('fetchAlerts failed');
                dispatch({ type: FETCH_ALERTS_FAILED, payload: { error } });
                if (error.response && ( error.response.status === 401 || error.response.status === 403)) {
                    present({message: 'Your session expired. Please reauthenticate.', duration: 3500, animated: true, position: 'bottom'});
                    logout!();
                }
            }
        }
    }

    function getUserAlertsEffect() {
        let canceled = false;
        fetchUserAlerts().then();
        return () => {
            canceled = true;
        }
        async function fetchUserAlerts() {
            if (!token?.trim()) {
                return;
            }
            try {
                log('fetchUserAlerts started');
                dispatch({type: FETCH_USER_ALERTS_STARTED});
                const user_alerts_response = await getUserAlerts(token, currentUserId);
                let userAlerts: AlertProps[];
                userAlerts = user_alerts_response.alerts;   // would have been the normal way :)))
                log('fetchUserAlerts succeeded');
                if (!canceled) {
                    dispatch({type: FETCH_USER_ALERTS_SUCCEEDED, payload: {userAlerts}});
                }
            } catch (error: any) {
                log('fetchUserAlerts failed');
                const {value} = await Storage.get({key: 'user_alerts'});
                if (!networkStatus.connected && value) {
                    const saved_userAlerts: AlertProps[] = JSON.parse(value);   // get the alerts saved in localstorage
                    if (!canceled) {
                        dispatch({ type: FETCH_USER_ALERTS_SUCCEEDED, payload: { userAlerts: saved_userAlerts } });
                    }
                }
                else dispatch({type: FETCH_USER_ALERTS_FAILED, payload: {error}});
            }
        }
    }

    async function saveAlertCallback(alert: AlertProps) {    // create / update alert
        try {
            log('saveAlert started');
            dispatch({ type: SAVE_ALERT_STARTED });
            let savedAlert: AlertProps;
            let alertId = alert.id;
            if(currentUserId === alert.userId)
                savedAlert = await (alertId ? updateAlert(token,alert) : createAlert(token,alert));
            if(alertId && currentUserId === alert.solverId ) {
                const updatedSolution: SolutionProps = {id: alertId, solverDescription: alert.solverDescription, isSolved: alert.isSolved, solverId: alert.solverId!};
                savedAlert = await updateSolution(token,updatedSolution);
            }
            log('saveAlert succeeded');
            dispatch({ type: SAVE_ALERT_SUCCEEDED, payload: { alert: savedAlert! } });
        } catch (error: any) {
            log('saveAlert failed: ' + error);
            if (!networkStatus.connected || (error.message && error.message === 'Network Error')){
                // const v = await Storage.get({key: 'alerts_to_retry'});
                // if(!v.value) await Storage.set({key: 'alerts_to_retry', value: JSON.stringify([])});
                //
                // const {value} = await Storage.get({key: 'alerts_to_retry'});
                // let alerts_to_retry: AlertProps[] = JSON.parse(value!);
                // const idx = alerts_to_retry.findIndex(a => a.id === alert.id);
                // if(idx && idx !== -1) alerts_to_retry[idx] = alert;
                // else alerts_to_retry.push(alert);  // else maybe create id for alert
                // await Storage.set({key: 'alerts_to_retry', value: JSON.stringify(alerts_to_retry)});
                present({
                    buttons: [{ text: 'dismiss' }],
                    message: 'Please retry once the server connection is reestablished.',
                    onDidDismiss: () => undefined,
                });
            }
            else {
                present({
                    message: error.response.data.message || 'Saving failed',
                    duration: 4000,
                    animated: true,
                    position: 'bottom'
                });
            }
            dispatch({ type: SAVE_ALERT_FAILED, payload: { error } });
        }
    }

    async function deleteAlertCallback(alert: AlertProps) {
        try {
            log('delete Solution-Alert started');
            dispatch({ type: DELETE_ALERT_STARTED });
            if(alert.isSolved && alert.solverId === currentUserId){
                await deleteSolution(token,alert);
                log('delete Solution succeeded');
                alert.isSolved = false;
                alert.solverId = undefined;
                alert.solverDescription = undefined;
                alert.solverPhoto = undefined;
                alert.solutionScore = undefined;
                dispatch({ type: SAVE_ALERT_SUCCEEDED, payload: { alert: alert } });
            }
            else {
                await deleteAlert(token,alert);
                log('delete Alert succeeded');
                dispatch({ type: DELETE_ALERT_SUCCEEDED, payload: { alert: alert } });
            }
        } catch (error: any) {
            log('delete Solution-Alert failed');
            if (!networkStatus.connected || (error.message && error.message === 'Network Error')){
                present({
                    buttons: [{ text: 'dismiss' }],
                    message: 'No server connection. Please try later.',
                    onDidDismiss: () => undefined,
                });
            }
            dispatch({ type: DELETE_ALERT_FAILED, payload: { error } });
        }
    }

    async function solveAlertCallback(alert: AlertProps) {
        try {
            log('solveAlert started');
            dispatch({ type: SAVE_ALERT_STARTED });
            const solution: SolutionProps = {id: alert.id,
                                             solverDescription: alert.solverDescription,
                                             isSolved: alert.isSolved,
                                             solverPhoto: alert.solverPhoto,
                                             solverId: alert.solverId!};
            let solveResponse = await solveAlertApi(token, solution);
            const solvedAlert = {...alert, id: solveResponse.id,
                                           solverDescription: solveResponse.solverDescription,
                                           solverPhoto: solveResponse.solverPhoto,
                                           isSolved: solveResponse.isSolved,
                                           solverId: solveResponse.solverId,
                                           solutionScore: solveResponse.solutionScore};
            log('solveAlert succeeded');
            dispatch({ type: SAVE_ALERT_SUCCEEDED, payload: { alert: solvedAlert } });
        } catch (error: any) {
            log('solveAlert failed: ' + error);
            if (!networkStatus.connected || (error.message && error.message === 'Network Error')){
                // const v = await Storage.get({key: 'alerts_to_retry'});
                // if(!v.value) await Storage.set({key: 'alerts_to_retry', value: JSON.stringify([])});
                //
                // const {value} = await Storage.get({key: 'alerts_to_retry'});
                // let alerts_to_retry: AlertProps[] = JSON.parse(value!);
                // const idx = alerts_to_retry.findIndex(a => a.id === alert.id);
                // if(idx && idx !== -1) alerts_to_retry[idx] = alert;
                // else alerts_to_retry.push(alert);  // else maybe create id for alert
                // await Storage.set({key: 'alerts_to_retry', value: JSON.stringify(alerts_to_retry)});
                present({
                    buttons: [{ text: 'dismiss' }],
                    message: 'Please retry once the server connection is reestablished.',
                    onDidDismiss: () => undefined,
                });
            }
            else {
                present({
                    message: error.response.data.message || 'Solving failed',
                    duration: 4000,
                    animated: true,
                    position: 'bottom'
                });
            }
            dispatch({ type: SAVE_ALERT_FAILED, payload: { error } });
        }
    }

    async function voteCallback(alert: AlertProps) {
        try {
            log('vote started');
            dispatch({ type: SAVE_ALERT_STARTED });
            let voteResponse: VoteResponseProps;
            let votedAlert: AlertProps;

            if(!alert.isSolved){
                voteResponse = await voteAlert(token, alert.id!, alert.currentUserVote === 'upvote');
                votedAlert = {...alert, alertScore: voteResponse.alertScore};
            }
            else{
                voteResponse = await voteSolution(token, alert.id!, alert.currentUserSolutionVote === 'upvote');
                votedAlert = {...alert, solutionScore: voteResponse.solutionScore, isSolved: voteResponse.solutionScore>=0};
            }
            updateReputation && updateReputation(voteResponse.voterScore);
            log('vote succeeded');
            dispatch({ type: SAVE_ALERT_SUCCEEDED, payload: { alert: votedAlert } });
        } catch (error: any) {
            log('vote failed: ' + error);
            if (!networkStatus.connected || (error.message && error.message === 'Network Error')){
                present({
                    buttons: [{ text: 'dismiss' }],
                    message: 'Please retry once the server connection is reestablished.',
                    onDidDismiss: () => undefined,
                });
            }
            else {
                present({
                    message: error.response.data.message || 'Voting failed',
                    duration: 4000,
                    animated: true,
                    position: 'bottom'
                });
            }
            dispatch({ type: SAVE_ALERT_FAILED, payload: { error } });
        }
    }
};
import React, { useContext, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { RouteComponentProps } from 'react-router';
import {
    IonButton,
    IonContent,
    IonHeader,
    IonInput,
    IonLoading,
    IonPage,
    IonTitle,
    IonToolbar,
    IonItem,
    IonLabel, IonText, IonButtons, IonRow, IonCheckbox, IonCol, useIonAlert, IonFooter
} from '@ionic/react';
import { AuthContext } from './AuthProvider';
import { getLogger } from '../core';
import '../pages/Home.css';

const log = getLogger('SignUp');

interface SignUpState {
  username?: string;
  password?: string;
  invitation?: string;
}

export const SignUp: React.FC<RouteComponentProps> = ({history}) => {
    const { isAuthenticated, isAuthenticating, authenticationError, signup } = useContext(AuthContext);
    const [state, setState] = useState<SignUpState>({});
    const [checked, setChecked] = useState(false);
    const [secondPass, setSecondPass] = useState('');
    const { username, password, invitation } = state;
    const [present] = useIonAlert();
    const handleSignUp = () => {
        log('handleSignUp...');
        if(secondPass === '' || password !== secondPass){
            present({
                header: 'Password mismatch',
                message: 'You did not type in the same password twice.',
                buttons: [{ text: 'Ok'}]
            })
            return;
        }
        if(!checked){
            present({
                header: 'Rules unchecked',
                message: 'You did not accept the GreenDefender rules.',
                buttons: [{ text: 'Ok'}]
            })
            return;
        }
        signup?.(username, password, invitation);
    };
    log('render');
    if (isAuthenticated) {
        return <Redirect to={{ pathname: '/' }} />
    }
      return (
        <IonPage>
          <IonHeader>
            <IonToolbar>
                <IonTitle slot="start" color="success">GreenDefender</IonTitle>
                <IonButtons slot="end">
                    <IonButton onClick={() => history.goBack()}> Back </IonButton>
                </IonButtons>
            </IonToolbar>
          </IonHeader>
          <IonContent class="ion-padding ion-text-center">
              <IonItem class="ion-text-center ion-margin-horizontal">
                  <IonLabel position="floating">Username</IonLabel>
                  <IonInput
                      value={username}
                      onIonChange={e => setState({
                          ...state,
                          username: e.detail.value || ''
                      })}/>
              </IonItem>

              <IonItem class="ion-text-center ion-margin-vertical ion-margin-horizontal">
                    <IonLabel position="floating">Password</IonLabel>
                    <IonInput
                      type="password"
                      required={true}
                      // placeholder="Password"
                      value={password}
                      onIonChange={e => setState({
                        ...state,
                        password: e.detail.value || ''
                      })}
                    />
              </IonItem>
              <IonItem class="ion-text-center ion-margin-vertical ion-margin-horizontal">
                  <IonLabel position="floating">Re-type password</IonLabel>
                  <IonInput
                      type="password"
                      required={true}
                      value={secondPass}
                      onIonChange={e => setSecondPass(e.detail.value || '')}
                  />
              </IonItem>

              <IonItem class="ion-text-center ion-margin-horizontal">
                  <IonLabel position="floating">Invitation code *</IonLabel>
                  <IonInput
                      value={invitation}
                      onIonChange={e => setState({
                          ...state,
                          invitation: e.detail.value || ''
                      })}/>
              </IonItem>

              <IonRow class="ion-padding-bottom ion-justify-content-center">

                      <IonButton className="ion-padding ion-margin-top" color="warning" shape="round"
                                 onClick={() => history.push('/rules')}>
                          Rules
                      </IonButton>

                      <IonItem className="ion-margin-top">
                          <IonCheckbox checked={checked} onIonChange={e => setChecked(e.detail.checked)} />
                          <IonLabel>&nbsp;&nbsp; Accept rules </IonLabel>
                      </IonItem>
              </IonRow>

              <IonLoading isOpen={isAuthenticating}/>
              <IonButton class="ion-margin" onClick={handleSignUp}>Sign Up</IonButton>

              {authenticationError && !authenticationError.response && (
                  <div> <IonText color="danger"> <b> Error: {authenticationError} </b> </IonText></div>
              )}

              {authenticationError && authenticationError.response && authenticationError.response.status === 400 && (
                  <div> <IonText color="danger"> <b> {'Invalid credentials'} </b> </IonText>
                      <p>
                          <IonText>
                          Make sure your password is at least <b>8 characters long</b> and contains at least one:
                          <b> uppercase & lowercase letter</b>, <b>number</b>, <b>special character</b>.
                          </IonText>
                      </p>
                  </div>
              )}

              {authenticationError && authenticationError.response && authenticationError.response.status === 404 && (
              <div> <IonText color="danger"> <b>{'Invitation code not found'} </b> </IonText></div>
              )}

              {authenticationError && authenticationError.response && authenticationError.response.status === 401 && (
                  <div> <IonText color="danger"> <b> {'Wrong username or password'} </b> </IonText>
                      <p>
                          <IonText>
                              Make sure your password is at least <b>8 characters long</b> and contains at least one:
                              <b> uppercase & lowercase letter</b>, <b>number</b>, <b>special character</b>.
                          </IonText>
                      </p>
                  </div>
              )}

              {authenticationError && authenticationError.response && authenticationError.response.status === 409 && (
                  <div> <IonText color="danger"> <b> {'User already exists'} </b> </IonText></div>
              )}

              <IonRow className="ion-align-items-center">
                  <IonCol className="ion-text-center">
                      <IonFooter className="ion-padding">
                          <p> </p>
                            * you can find details about getting an invitation code in the Rules
                          <p> </p>
                      </IonFooter>
                  </IonCol>
              </IonRow>
          </IonContent>
        </IonPage>
      );
};

import React, { useContext, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { RouteComponentProps } from 'react-router';
import {
    IonButton,
    IonContent,
    IonHeader,
    IonInput,
    IonLoading,
    IonPage,
    IonTitle,
    IonToolbar,
    IonItem,
    IonLabel, IonText, IonButtons
} from '@ionic/react';
import { AuthContext } from './AuthProvider';
import { getLogger } from '../core';
import '../pages/Home.css';

const log = getLogger('Login');

interface LoginState {
  username?: string;
  password?: string;
}

export const Login: React.FC<RouteComponentProps> = ({history}) => {
    const { isAuthenticated, isAuthenticating, login, authenticationError, logout} = useContext(AuthContext);
    const [state, setState] = useState<LoginState>({});
    const { username, password } = state;
    const handleLogin = () => {
        log('handleLogin...');
        login?.(username, password);
    };
    log('render');
    if (isAuthenticated) {
        return <Redirect to={{ pathname: '/' }} />
    }
      return (
        <IonPage>
          <IonHeader>
            <IonToolbar>
                <IonTitle slot="start" color="success">GreenDefender</IonTitle>
                <IonButtons slot="end">
                    <IonButton onClick={() => { logout && logout(); history.goBack();}}> Back </IonButton>
                </IonButtons>
            </IonToolbar>
          </IonHeader>
          <IonContent class="ion-padding ion-text-center">
              <IonItem class="ion-text-center ion-margin-horizontal">
                  <IonLabel position="floating">Username</IonLabel>
                  <IonInput
                      value={username}
                      onIonChange={e => setState({
                          ...state,
                          username: e.detail.value || ''
                      })}/>
              </IonItem>

              <IonItem class="ion-text-center ion-margin-vertical ion-margin-horizontal">
                    <IonLabel position="floating">Password</IonLabel>
                    <IonInput
                      type="password"
                      required={true}
                      // placeholder="Password"
                      value={password}
                      onIonChange={e => setState({
                        ...state,
                        password: e.detail.value || ''
                      })}
                      onKeyPress={(k:any) => { if(k.key === 'Enter') handleLogin();}}
                    />
              </IonItem>
              <IonLoading isOpen={isAuthenticating}/>
              <IonButton class="ion-margin" onClick={handleLogin}>Login</IonButton>
              {/*<IonButton class="ion-margin" onClick={handleSignUp}>Sign up</IonButton>*/}

              {authenticationError && !authenticationError.response && (
                  <div> <IonText color="danger"> <b> Error: {authenticationError} </b> </IonText></div>
              )}

              {authenticationError && authenticationError.response && authenticationError.response.status === 400 && (
                  <div> <IonText color="danger"> <b> {'Invalid credentials'} </b> </IonText>
                      <p>
                          <IonText>
                          Make sure your password is at least <b>8 characters long</b> and contains at least one:
                          <b> uppercase & lowercase letter</b>, <b>number</b>, <b>special character</b>.
                          </IonText>
                      </p>
                  </div>
              )}

              {authenticationError && authenticationError.response && authenticationError.response.status === 404 && (
              <div> <IonText color="danger"> <b>{'User does not exist'} </b> </IonText></div>
              )}

              {authenticationError && authenticationError.response && authenticationError.response.status === 401 && (
                  <div> <IonText color="danger"> <b> {'Wrong username or password'} </b> </IonText>
                      {/*<p>*/}
                      {/*    <IonText>*/}
                      {/*        Make sure your password is at least <b>8 characters long</b> and contains at least one:*/}
                      {/*        <b> uppercase & lowercase letter</b>, <b>number</b>, <b>special character</b>.*/}
                      {/*    </IonText>*/}
                      {/*</p>*/}
                  </div>
              )}

              {authenticationError && authenticationError.response && authenticationError.response.status === 409 && (
                  <div> <IonText color="danger"> <b> {'User already exists'} </b> </IonText></div>
              )}
          </IonContent>
        </IonPage>
      );
};

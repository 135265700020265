import {
    IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonList, IonItem, IonFooter, IonLabel, IonToast, IonGrid,
    IonRow, IonCol, IonText, IonAvatar, IonImg,
} from '@ionic/react';
import React, { useEffect, useState,} from 'react';
import { RouteComponentProps } from 'react-router';
import './Home.css';
import {IonButton, IonButtons, } from '@ionic/react';
import {getLogger, round} from "../core";
import {UserProps} from "../auth/UserProps";
import {getTopUsers} from "../auth/authApi";
const log = getLogger('WelcomePage');

const Leaderboard: React.FC<RouteComponentProps> = ({ history }) => {
    const [topUsers, setTopUsers] = useState<UserProps[]>();
    const [fetchingError, setFetchingError] = useState(false);
    useEffect(() => {
        fetchTop100Users().then();
        async function fetchTop100Users() {
            try {
                log('fetchTop100Users started');
                const top_users_response = await getTopUsers();
                setTopUsers(top_users_response.users);
                log('fetchTop100Users succeeded');
                setFetchingError(false);
            } catch (error: any) {
                log('fetchTop100Users failed');
                setFetchingError(true);
            }
        }
    }, []);
    log('render');
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle color="success"> GreenDefender </IonTitle>
            <IonButtons slot="end">
                <IonButton onClick={() => history.goBack()}> Back </IonButton>
            </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen className="ion-padding">
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">GreenDefender</IonTitle>
          </IonToolbar>
        </IonHeader>
          <div className="container ion-padding">
              <IonAvatar className="avatar">
                  <IonImg src={"/assets/icon/icon.png"} />
              </IonAvatar>
              <p>  &nbsp; </p>
              <strong>Top 100 <i>GreenDefenders</i></strong>
              <p>  &nbsp; </p>
          </div>

          <IonToast isOpen={!topUsers} message="Fetching top 100 members..." />

          <IonGrid className="ion-align-items-center ion-text-center">
              <IonRow className="ion-padding ion-text-center">
                  <IonCol> <IonLabel color="success"> <b> RANK </b></IonLabel> </IonCol>
                  <IonCol> <IonLabel color="success"> <b> USERNAME </b></IonLabel> </IonCol>
                  <IonCol> <IonLabel color="success"> <b> REPUTATION </b></IonLabel> </IonCol>
              </IonRow>
              {topUsers && topUsers
                          .map((user,idx) =>
                              {
                                  return (
                                      <IonRow color="success" key={idx} className="ion-padding-start ion-padding-end ion-margin-bottom" >
                                          <IonCol><IonText> <b> {idx+1} </b></IonText></IonCol>
                                          <IonCol>{user.username}</IonCol>
                                          <IonCol><b>{round(user.reputationScore, 5)}</b></IonCol>
                                      </IonRow>
                                  );
                              }
                          )
              }
          </IonGrid>

          {fetchingError && (
              <div><p>{'No server connection.' || 'Failed to fetch top members'}</p></div>
          )}

          <IonGrid>
              <IonRow className="ion-align-items-center">
                  <IonCol className="ion-text-center">
                      <IonButton className="ion-padding" color="warning" shape="round"
                                 onClick={() => history.push('/rules')}>
                          Rules
                      </IonButton>
                  </IonCol>
              </IonRow>
          </IonGrid>
          <IonRow className="ion-align-items-center">
              <IonCol className="ion-text-center">
                  <IonFooter className="ion-padding">
                      <p> </p>
                      <i>Earth provides enough to satisfy every man’s needs, but not every man’s greed.</i>
                      <p> </p>
                  </IonFooter>
              </IonCol>
          </IonRow>
      </IonContent>
    </IonPage>
  );
};

export default Leaderboard;

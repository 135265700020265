export const http = 'https';
//export const baseUrl = 'localhost:8080';   // local backend
//export const baseUrl = 'greendefender-backend-spring.herokuapp.com';   // deployed backend
export const baseUrl = 'greendefender.mstefanc.com';   // deployed backend

export const getLogger: (tag: string) => (...args: any) => void =
    tag => (...args) => console.log(tag, ...args);

const log = getLogger('api');

export interface ResponseProps<T> {
    data: T;
}

export function withLogs<T>(promise: Promise<ResponseProps<T>>, fnName: string): Promise<T> {
    log(`${fnName} - started`);
    return promise
        .then(res => {
            log(`${fnName} - succeeded`);
            return Promise.resolve(res.data);
        })
        .catch(err => {
            log(`${fnName} - failed`);
            return Promise.reject(err);
        });
}

export const config = {
    headers: {
        'Content-Type': 'application/json'
    }
};

export const authConfig = (token?: string) => ({
    headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
    }
});

export function round( value: number, dp: number ){
    return +parseFloat(String(value)).toFixed( dp );
}

import React from 'react';
import {IonCol, IonImg, IonItem, IonText} from '@ionic/react';
import { AlertProps } from '../core/AlertProps';
import {getDistanceFromLatLonInKm} from "./MyMap";
import {round} from "../core";

interface AlertPropsExt extends AlertProps {
    selected?: string;
    currentLocation?: {latitude: number | undefined, longitude: number | undefined};
    onEdit: (_id?: string) => void;
}

const Alert: React.FC<AlertPropsExt> = ({   id,
                                            title, category,
                                            reportedPhoto,
                                            location,
                                            currentLocation,
                                            alertScore, solutionScore,
                                            isSolved, selected, onEdit }) => {
    if(selected === 'solutions' && !isSolved) return null;
    return (
        <IonItem onClick={() => onEdit(id)} color="positive" button={true} className="ion-margin-end">
            <IonCol><b>{title}</b></IonCol>
            <IonCol size="2"  className="ion-margin-end">
                {/*Reported photo*/}
                {reportedPhoto && <IonImg class="alert-photo" src={reportedPhoto}/>}
            </IonCol>
            {/*<IonCol>{category}</IonCol>*/}
            <IonCol>
                {(!currentLocation?.latitude || !currentLocation?.longitude) && <i>...</i>}
                {currentLocation?.latitude && currentLocation.longitude &&
                    (round(getDistanceFromLatLonInKm(location?.latitude!,location?.longitude!,currentLocation?.latitude!,currentLocation?.longitude!),2))} km away
            </IonCol>
            <IonCol>
                {isSolved ? <IonText color="warning">check solution!</IonText> : <IonText color="danger">no solution yet</IonText>}
            </IonCol>
            <IonCol>
                {(selected==='solutions' && solutionScore!== undefined) ?
                    <IonText> <IonText color="secondary"> score&nbsp; </IonText> {round(solutionScore,2)} </IonText> :
                    <IonText> <IonText color="primary"> score&nbsp; </IonText> {round(alertScore,2)} </IonText> }
            </IonCol>
        </IonItem>
    );
};

export default Alert;
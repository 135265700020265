import {
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    IonList,
    IonItem,
    IonFooter,
    IonLabel,
    IonToast,
    IonAlert,
    IonGrid, IonRow, IonCol, IonText,
} from '@ionic/react';
import React, {useContext, useEffect, useState,} from 'react';
import ExploreContainer from '../components/ExploreContainer';
import { RouteComponentProps } from 'react-router';
import './Home.css';
import {AuthContext} from "../auth";
import {IonButton, IonButtons, } from '@ionic/react';
import {MyMap} from "../components/MyMap";
import {getLogger} from "../core";
import {AlertContext} from "../core/AlertProvider";
import Alert from '../components/Alert';
import {Redirect} from "react-router-dom";
import {getWelcomeAlerts} from "../core/alertApi";
import {AlertProps} from "../core/AlertProps";
const log = getLogger('WelcomePage');

const WelcomePage: React.FC<RouteComponentProps> = ({ history }) => {
    const { isAuthenticated } = useContext(AuthContext);
    const { c_lat, c_lng } = useContext(AlertContext);
    let coordinates: [number, number][]= [];
    const [nearbyAlerts, setNearbyAlerts] = useState<AlertProps[]>();
    const [fetchingError, setFetchingError] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    useEffect(() => {
        fetchWelcomeAlerts().then();
        async function fetchWelcomeAlerts() {
            if(!c_lat || !c_lng || nearbyAlerts) return;
            try {
                log('fetchWelcomeAlerts started');
                const welcome_alerts_response = await getWelcomeAlerts({latitude: c_lat, longitude: c_lng});
                setNearbyAlerts(welcome_alerts_response.alerts);
                log('fetchWelcomeAlerts succeeded');
                setFetchingError(false);
            } catch (error: any) {
                log('fetchWelcomeAlerts failed');
                setFetchingError(true);
            }
        }
    }, [c_lat,c_lng, ]);
    log('render');
    if (isAuthenticated) { return <Redirect to={{ pathname: '/' }} /> }
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle color="success"> GreenDefender </IonTitle>
            <IonButtons slot="end">
                <IonButton color="primary" onClick={() => history.push('/signup')}> Sign Up </IonButton>
                <IonButton color="primary" onClick={() => history.push('/login')}> Login </IonButton>
            </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen className="ion-padding">
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">GreenDefender</IonTitle>
          </IonToolbar>
        </IonHeader>
          <ExploreContainer />
            <IonGrid>
                <IonRow className="ion-align-items-center">
                    <IonCol className="ion-text-center">
                        <IonButton color="success" shape="round"
                            onClick={() => history.push('/leaderboard')}>
                            Leaderboard
                        </IonButton>
                    </IonCol>
                </IonRow>
            </IonGrid>
          <p className="ion-margin-start">You can find the alerts near you below . . .</p>
          <IonItem>
              <IonLabel>Maximum distance: 10 km</IonLabel>
          </IonItem>

          <IonToast isOpen={!nearbyAlerts} message="Fetching nearby alerts..." />
          {(!c_lat || !c_lng) &&
              <div> <p> Searching your GPS location...  </p>
                  <p> <IonText color="medium">Make sure the location is 'on' in your device settings.</IonText> </p>
                  <p> <IonText color="medium">Restart the app if it takes more than 1 minute.</IonText> </p>
              </div>
          }
          {c_lat && c_lng && coordinates && nearbyAlerts &&
              <MyMap
                  c_lat={c_lat}
                  c_lng={c_lng}
                  centerUser={true}
                  coordinates={coordinates}
                  zoom={12}
                  onMapClick={(e: google.maps.MapMouseEvent) => {console.log({latitude: e.latLng!.toJSON().lat, longitude: e.latLng!.toJSON().lng});}}
                  onMarkerClick={() => { log('onMarker'); setShowAlert(true); }}
              />
          }
          {c_lat && c_lng && !nearbyAlerts && <MyMap c_lat={c_lat} c_lng={c_lng} zoom={12}/>}
          <p> </p>

          {nearbyAlerts && c_lat && c_lng && (
              <IonList>
                  {nearbyAlerts
                      .map((alert) =>
                          {
                              if(alert.alertScore >= -10) coordinates.push([alert.location!.latitude!,alert.location!.longitude!]);
                              return (<Alert key={alert.id}   id={alert.id}
                                                     title={alert.title}
                                               description={alert.description}
                                                  category={alert.category}
                                             reportedPhoto={alert.reportedPhoto}
                                               solverPhoto={alert.solverPhoto}
                                                  isSolved={alert.isSolved}
                                                  location={alert.location}
                                                    userId={alert.userId}
                                                  solverId={alert.solverId}
                                                alertScore={alert.alertScore}
                                             solutionScore={alert.solutionScore}
                                         solverDescription={alert.solverDescription}
                                           currentLocation={{latitude: c_lat, longitude: c_lng}}
                                                  selected='alerts'
                                                    onEdit={() => setShowAlert(true)}  />);
                          }
                      )
                  }
              </IonList>
          )}
          {fetchingError && (
              <div><p>{'No server connection.' || 'Failed to fetch nearby alerts'}</p></div>
          )}
          <IonAlert
              isOpen={showAlert}
              onDidDismiss={() => setShowAlert(false)}
              header={'Authentication required'}
              message={'Please login or sign up for more details.'}
              buttons={['OK']}
          />

          <IonGrid>
              <IonRow>
                  <IonText color="warning">* the app requires location access to find the alerts near you</IonText>
              </IonRow>
              <IonRow>
                  <IonText>* the red dot shows your location, the other red markers show the alerts </IonText>
              </IonRow>
              <IonRow className="ion-align-items-center">
                  <IonCol className="ion-text-center">
                      <IonButton className="ion-padding" color="warning" shape="round"
                                 onClick={() => history.push('/rules')}>
                          Rules
                      </IonButton>
                  </IonCol>
              </IonRow>
          </IonGrid>
          <IonRow className="ion-align-items-center">
              <IonCol className="ion-text-center">
                  <IonFooter className="ion-padding">
                      <p> </p>
                      <i>Earth provides enough to satisfy every man’s needs, but not every man’s greed.</i>
                      <p> </p>
                  </IonFooter>
              </IonCol>
          </IonRow>
      </IonContent>
    </IonPage>
  );
};

export default WelcomePage;

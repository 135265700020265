import axios from 'axios';
import { authConfig, config, http, baseUrl, getLogger, withLogs } from '../core';
import { AlertProps } from './AlertProps';
import {SolutionProps} from "./SolutionProps";

const alertUrl = `${http}://${baseUrl}/api`;

export interface AlertsResponseProps {
    currentUserReputation?: number;
    alerts: AlertProps[];
}

export interface VoteResponseProps {
    id: number;
    alertScore: number;
    solutionScore: number;
    voterScore: number;
}

export const getWelcomeAlerts: (location: {latitude: number, longitude: number}) => Promise<AlertsResponseProps> = (location) => {
    return withLogs(axios.post(`${alertUrl}/alerts/welcome`, location, config), 'getWelcomeAlerts');
}

export const getNearbyAlerts: (token: string, location: {latitude: number, longitude: number}, maxDistance: number) => Promise<AlertsResponseProps> = (token, location,maxDistance) => {
    return withLogs(axios.post(`${alertUrl}/alerts?distance=${maxDistance}`, location, authConfig(token)), 'getNearbyAlerts');
}

export const getUserAlerts: (token: string, userId: string) => Promise<AlertsResponseProps> = (token, userId) => {
    return withLogs(axios.get(`${alertUrl}/user/alerts/${userId}`, authConfig(token)), 'getUserAlerts');
}

export const createAlert: (token: string, alert: AlertProps) => Promise<AlertProps> = (token, alert) => {
    return withLogs(axios.post(`${alertUrl}/user/alerts`, alert, authConfig(token)), 'createAlert');
}

export const updateAlert: (token: string, alert: AlertProps) => Promise<AlertProps> = (token, alert) => {
    const alert_id = alert.id; alert.id = undefined;
    return withLogs(axios.put(`${alertUrl}/user/alerts/${alert_id}`, alert, authConfig(token)), 'updateAlert');
}

export const updateSolution: (token: string, solution: SolutionProps) => Promise<AlertProps> = (token, solution) => {
    const alert_id = solution.id; solution.id = undefined;
    return withLogs(axios.put(`${alertUrl}/user/solutions/${alert_id}`, solution, authConfig(token)), 'updateSolution');
}

export const solveAlert: (token: string, solution: SolutionProps) => Promise<SolutionProps> = (token, solution) => {
    const alert_id = solution.id; solution.id = undefined;
    return withLogs(axios.post(`${alertUrl}/alerts/solution/${alert_id}`, solution, authConfig(token)), 'solveAlert');
}

export const deleteAlert: (token: string, alert: AlertProps) => Promise<any> = (token, alert) => {
    return withLogs(axios.delete(`${alertUrl}/user/alerts/${alert.id}`, authConfig(token)), 'deleteAlert');
}

export const deleteSolution: (token: string, alert: AlertProps) => Promise<any> = (token, alert) => {
    return withLogs(axios.delete(`${alertUrl}/user/alerts/solution/${alert.id}`, authConfig(token)), 'deleteSolution');
}

export const voteAlert: (token: string, alert_id: string, upvote: boolean) => Promise<VoteResponseProps> = (token, alert_id, upvote) => {
    return withLogs(axios.put(`${alertUrl}/alerts/vote/${alert_id}`, { upvote }, authConfig(token)), 'voteAlert');
}

export const voteSolution: (token: string, alert_id: string, upvote: boolean) => Promise<VoteResponseProps> = (token, alert_id, upvote) => {
    return withLogs(axios.put(`${alertUrl}/alerts/solution/vote/${alert_id}`, { upvote }, authConfig(token)), 'voteSolution');
}

interface MessageData {
    event: string;
    payload: AlertProps;
}

const log = getLogger('ws');

export const newWebSocket = (token: string, onMessage: (data: MessageData) => void) => {
    const ws = new WebSocket(`ws://${baseUrl}`)
    ws.onopen = () => {
        log('web socket onopen');
        ws.send(JSON.stringify({ type: 'authorization', payload: { token } }));
    };
    ws.onclose = () => {
        log('web socket onclose');
    };
    ws.onerror = error => {
        log('web socket onerror', error);
    };
    ws.onmessage = messageEvent => {
        log('web socket onmessage');
        onMessage(JSON.parse(messageEvent.data));
    };
    return () => {
        ws.close();
    }
}

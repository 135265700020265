import React, { useContext, useEffect, useState } from 'react';
import {
    IonButton,
    IonButtons,
    IonLabel,
    IonItem,
    IonContent,
    IonHeader,
    IonInput,
    IonPage,
    IonTitle,
    IonToolbar,
    IonLoading,
    IonCol,
    IonImg,
    IonIcon,
    IonActionSheet,
    IonTextarea,
    useIonAlert,
    IonText,
    IonSelect,
    IonSelectOption,
    IonItemDivider, IonRadioGroup, IonRow, IonRadio
} from '@ionic/react';
import {getLogger, round} from '../core';
import { RouteComponentProps } from 'react-router';
import {MyMap, getDistanceFromLatLonInKm} from "../components/MyMap";
//import { useMyLocation } from '../core/useMyLocation';
import {usePhotoCamera} from "../core/usePhotoCamera";
import {AlertProps} from "../core/AlertProps";
import {camera, trash, close, copy} from "ionicons/icons";
import {AlertContext} from "../core/AlertProvider";
import {AuthContext} from "../auth";
import {Clipboard} from "@capacitor/clipboard";

const log = getLogger('AlertEdit');

interface AlertEditProps extends RouteComponentProps<{
    id?: string;
}> {}

const AlertEdit: React.FC<AlertEditProps> = ({ history, match }) => {
    const {currentUserId} = useContext(AuthContext);
    const {userAlerts, saving, saveAlert, deleting, delAlert, c_lat: lat, c_lng: lng, maxDistance} = useContext(AlertContext);
    const [description, setDescription] = useState('');
    const [category, setCategory] = useState('');
    const [reportedPhoto, setReportedPhoto] = useState<string|undefined>(undefined);
    //const [location, setLocation] = useState<{latitude:number|undefined, longitude:number|undefined}>({latitude:0, longitude:0});
    const [solverDescription, setSolverDescription] = useState('');
    const [alert, setAlert] = useState<AlertProps>();
    const { takePhoto, deletePhoto } = usePhotoCamera();
    const [photoToDelete, setPhotoToDelete] = useState<string>();
    const [present] = useIonAlert();

    useEffect(() => {
        log('useEffect');
        const routeId = match.params.id || '';
        if(routeId === '') history.goBack();
        const alert = userAlerts?.find(a => a.id === routeId);
        if (!alert) history.goBack();
        setAlert(alert);
        setDescription(alert?.description || '');
        setReportedPhoto(alert?.reportedPhoto);
        setCategory(alert?.category || '');
        setSolverDescription(alert?.solverDescription || '');
    }, [match.params.id, userAlerts]);
    const handleSave = () => {
        const editedAlert = { ...alert!, description, reportedPhoto, category, solverDescription };
        saveAlert && saveAlert(editedAlert).then(() => history.goBack());
    };
    const handleDelete = () => {
        present({
            header: 'Confirm deletion',
            message: 'The alert/solution and all its corresponding votes will be erased. The solution is erased first.',
            buttons: [
                'Cancel',
                { text: 'CONFIRM',
                    handler: () => {
                        delAlert && delAlert(alert!).then(() => history.goBack());
                    }
                },
            ]
        })
    };

    const handleCancel = () => {
        history.goBack();
    };

    log('render');
    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonTitle>Alert <IonText color="success">{alert?.id}</IonText></IonTitle>
                    <IonButtons slot="end">
                        <IonButton onClick={handleSave} color="success">
                            Save
                        </IonButton>
                        <IonButton onClick={handleDelete} color="danger">
                            Delete
                        </IonButton>
                        <IonButton onClick={handleCancel}>
                            Cancel
                        </IonButton>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <IonContent className="ion-padding">
                <IonItem>
                    <IonLabel color="danger">Title:</IonLabel>
                    <IonInput placeholder="Enter title" value={alert?.title} readonly/>
                </IonItem>
                <IonItem>
                    <IonLabel color="danger">Description:</IonLabel>
                    {currentUserId === alert?.userId &&
                        <IonTextarea placeholder="Describe the problem" value={description} onIonChange={e => setDescription(e.detail.value || '')} />
                    }
                    {currentUserId !== alert?.userId &&
                        <IonTextarea value={description} readonly/>
                    }
                </IonItem>

                <IonItem>
                    <IonLabel color="danger">Reported photo:</IonLabel>
                    {reportedPhoto &&
                        <IonCol size="4">
                        <IonImg onClick={() => {
                            if(currentUserId === alert?.userId) setPhotoToDelete(reportedPhoto);
                            present({
                                header: 'Reported photo',
                                message: `<img src="${reportedPhoto}" alt="r-img" >`,
                            });
                        }}
                                src={reportedPhoto}/>
                        </IonCol>
                    }
                    { currentUserId === alert?.userId &&
                        <IonCol>
                            <IonButton onClick={() => takePhoto().then(res => res? setReportedPhoto(res.data) : undefined)}>
                                <IonIcon icon={camera}/>
                            </IonButton>
                        </IonCol>
                    }
                </IonItem>

                { currentUserId === alert?.userId &&
                    <IonActionSheet
                        isOpen={!!photoToDelete}
                        buttons={[{
                            text: 'Delete',
                            role: 'destructive',
                            icon: trash,
                            handler: () => {
                                if (photoToDelete) {
                                    deletePhoto({filepath: '', data: photoToDelete}).then(_ => {
                                        if(reportedPhoto && photoToDelete === reportedPhoto)
                                            setReportedPhoto(undefined);
                                        setPhotoToDelete(undefined);
                                    });
                                }
                            }
                        }, {
                            text: 'Cancel',
                            icon: close,
                            role: 'cancel'
                        }]}
                        onDidDismiss={() => setPhotoToDelete(undefined)}
                    />
                }

                {currentUserId === alert?.userId &&
                    <IonItem>
                        <IonLabel color="danger">Category:</IonLabel>
                        <IonSelect interface="popover" value={category} onIonChange={e => setCategory(e.detail.value || '')}>
                            <IonSelectOption value="air pollution"> air pollution </IonSelectOption>
                            <IonSelectOption value="deforestation"> deforestation </IonSelectOption>
                            <IonSelectOption value="household waste"> household waste </IonSelectOption>
                            <IonSelectOption value="industrial waste"> industrial waste </IonSelectOption>
                            <IonSelectOption value="landfill"> landfill </IonSelectOption>
                            <IonSelectOption value="littering"> littering </IonSelectOption>
                            <IonSelectOption value="logging"> logging </IonSelectOption>
                            <IonSelectOption value="poaching"> poaching </IonSelectOption>
                            <IonSelectOption value="soil pollution"> soil pollution </IonSelectOption>
                            <IonSelectOption value="water pollution"> water pollution </IonSelectOption>
                        </IonSelect>
                    </IonItem>
                }

                {currentUserId !== alert?.userId &&
                    <IonItem>
                        <IonLabel color="danger">Category:</IonLabel>
                        <IonLabel className="ion-text-right"> {category} </IonLabel>
                    </IonItem>
                }

                <IonItem>
                    <IonLabel color="danger">GPS Location:</IonLabel>
                </IonItem>
                <IonItem>
                    <IonCol>
                        lat { round(alert?.location!.latitude!,10)}
                    </IonCol>
                    <IonCol>
                        long { round(alert?.location!.longitude!, 10)}
                    </IonCol>
                    <IonCol>
                        <IonButton size="small" color="medium" shape="round"
                                   onClick={()=>Clipboard.write({string: lat + ', ' + lng})}>
                            <IonIcon slot="icon-only" icon={copy} />
                        </IonButton>
                    </IonCol>
                </IonItem>

                {(!lat || !lng) &&
                    <p> Searching your GPS location... </p>
                }
                {lat && lng && alert?.location &&
                <MyMap
                    c_lat={lat}
                    c_lng={lng}
                    centerUser={false}
                    zoom={maxDistance===10 ? 12 : (maxDistance===5 ? 13 : (maxDistance===1 ? 15 : (maxDistance===20 ? 11 : 9)))}
                    coordinates={ [[alert?.location!.latitude,alert?.location!.longitude]]}
                /> }

                {lat && lng && alert && getDistanceFromLatLonInKm(lat,lng,alert.location?.latitude!,alert.location?.longitude!)>maxDistance &&
                    <p>
                        <IonText color="warning"> Warning!
                        You are {round(getDistanceFromLatLonInKm(lat,lng,alert.location?.latitude!,alert.location?.longitude!),2)} km
                        away from this alert. </IonText>
                    </p>
                }

                <IonItem className="ion-margin-top">
                    <IonLabel color="danger">Alert score:</IonLabel>
                    <IonInput value={alert?.alertScore} readonly/>
                </IonItem>

                { alert?.isSolved && alert.solverId === currentUserId &&
                    <div>
                        <IonItemDivider/>
                        <p> <IonText color="success">Solution</IonText> </p>

                        {alert?.solverPhoto &&
                            <IonItem>
                                <IonLabel color="danger">Solution photo:</IonLabel>
                                <IonCol size="4">
                                    <IonImg src={alert?.solverPhoto}/>
                                </IonCol>
                            </IonItem>
                        }

                        <IonItem>
                            <IonLabel color="danger">Solution description:</IonLabel>
                            <IonTextarea placeholder="Describe your solution" value={solverDescription}
                                         onIonChange={e => setSolverDescription(e.detail.value || '')} />
                        </IonItem>

                        <IonItem>
                            <IonLabel color="danger">Solved:</IonLabel>
                            <IonRadioGroup value={'Yes'}>
                                <IonRow>
                                    <IonItem className="ion-margin-start">
                                        <IonLabel>Yes</IonLabel>
                                        <IonRadio slot="start" value="Yes"/>
                                    </IonItem>
                                    <IonItem className="ion-margin-end">
                                        <IonLabel>No</IonLabel>
                                        <IonRadio slot="start" value="No" disabled/>
                                    </IonItem>
                                </IonRow>
                            </IonRadioGroup>
                        </IonItem>

                        <IonItem className="ion-margin-bottom">
                            <IonLabel color="danger">Solution score:</IonLabel>
                            <IonInput value={alert?.solutionScore} readonly/>
                        </IonItem>
                    </div>
                }

                <IonLoading isOpen={saving} message={'Saving...'}/>
                {/*{savingError && (*/}
                {/*    <div className="ion-padding">{savingError.message || 'Failed to save alert'}</div>*/}
                {/*)}*/}
                <IonLoading isOpen={deleting} message={'Deleting...'}/>
                {/*{deletingError && (*/}
                {/*    <div className="ion-padding">{deletingError.message || 'Failed to delete alert'}</div>*/}
                {/*)}*/}
            </IonContent>
        </IonPage>
    );

}

export default AlertEdit;
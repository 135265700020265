import React, {useCallback, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import { getLogger } from '../core';
import {
  login as loginApi,
  signup as signupApi,
  changePassword as changePasswordApi,
  deleteUser
} from './authApi';
import {Storage} from "@capacitor/storage";
import {useIonToast} from '@ionic/react';
import {UserProps} from "./UserProps";

const log = getLogger('AuthProvider');

type LoginFn = (username?: string, password?: string) => void;
type SignUpFn = (username?: string, password?: string, invitation?: string) => void;
type ReputationFn = (newReputation: number) => void;
type ChangePasswordFn = (password?: string, newPassword?: string) => void;

export interface AuthState {
  authenticationError: Error | any;
  isAuthenticated: boolean;
  isAuthenticating: boolean;
  login?: LoginFn;
  logout?: () => void;
  signup?: SignUpFn;
  updateReputation?: ReputationFn;
  changePassword?: ChangePasswordFn;
  removeAccount?: LoginFn;
  generateInvitation?: (token: string) => void;
  pendingAuthentication?: boolean;
  pendingSignUp?: boolean;
  pendingRemove?: boolean;
  username: string;
  password?: string;
  invitation?: string;
  token: string;
  currentUserId: string;
  reputationScore: number;
  targetUsername?: string;
}

const initialState: AuthState = {
  isAuthenticated: false,
  isAuthenticating: false,
  authenticationError: null,
  pendingAuthentication: false,
  pendingSignUp: false,
  pendingRemove: false,
  token: '',
  username: '',
  currentUserId: '',
  reputationScore: 0.0
};

export const AuthContext = React.createContext<AuthState>(initialState);

interface AuthProviderProps {
  children: PropTypes.ReactNodeLike,
}

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [state, setState] = useState<AuthState>(initialState);
  const { isAuthenticated, isAuthenticating, authenticationError, pendingAuthentication, token, currentUserId, username, invitation, reputationScore, pendingRemove } = state;
  const login = useCallback<LoginFn>(loginCallback, [isAuthenticated, token]);
  const logout = useCallback<() => void>(logOutCallback, [isAuthenticated, token]);
  const signup = useCallback<SignUpFn>(signupCallback, [isAuthenticated, token]);
  const updateReputation = useCallback<ReputationFn>(updateReputationCallback, [isAuthenticated, token]);
  const changePassword = useCallback<ChangePasswordFn>(changePasswordCallback, [isAuthenticated, token]);
  const removeAccount = useCallback<LoginFn>(removeAccountCallback, [isAuthenticated, token]);
  useEffect(authenticationEffect, [pendingAuthentication]);
  useEffect(accountRemoveEffect, [pendingRemove]);
  const value = { isAuthenticated, login, logout, signup, isAuthenticating, authenticationError, token, currentUserId,
                  username, invitation, reputationScore, updateReputation, changePassword, removeAccount };
  const [present] = useIonToast();
  log('render');
  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );

  function loginCallback(username?: string, password?: string): void {
    log('login');
    Storage.set({
      key: 'username',
      value: username!
    }).then();
    setState({
      ...state,
      username: username!,
      password: password,
      pendingAuthentication: true
    });
  }

  async function removeAccountCallback(targetUsername?: string, targetPassword?: string){
    log('removeAccount signal');
    setState({
      ...state,
      password: targetPassword,
      targetUsername: targetUsername,
      pendingRemove: true
    });
  }

  async function changePasswordCallback(password?: string, newPassword?: string) {
    log('changePassword');
    changePasswordAsync().then();
    async function changePasswordAsync() {
      try {
        const storedToken = await Storage.get({ key: 'userToken' });
        const savedUsername = await Storage.get({ key: 'username' });
        if (!storedToken.value || !savedUsername.value) return;
        log('trying password change...');
        await changePasswordApi(storedToken.value,savedUsername.value,password!,newPassword!);
        logOutCallback();
        present({
          message: 'Password changed. Please re-authenticate.',
          duration: 3500,
          animated: true,
          position: 'bottom'
        });
      } catch (error: any) {
        log('password change failed');
        if (!error.response) {
          present({
            message: 'Please check your internet connection...',
            duration: 3500,
            animated: true,
            position: 'bottom'
          });
        }
        else {
          present({
            message: error.response.data.message || 'Authentication failed',
            duration: 4000,
            animated: true,
            position: 'bottom'
          });
        }
      }
    }
  }

  async function updateReputationCallback(newReputation: number) {
    log('updateReputation');
    if(newReputation === reputationScore) return;
    Storage.set({
      key: 'reputationScore',
      value: String(newReputation)
    }).then();
    if(!state.isAuthenticated) return;
    setState({
      ...state,
      reputationScore: newReputation
    });
    log('updateReputation done');
  }

  function signupCallback(username?: string, password?: string, invitation?: string): void {
    log('signup');
    Storage.set({
      key: 'username',
      value: username!
    }).then();
    setState({
      ...state,
      username: username!,
      password: password,
      invitation: invitation,
      pendingSignUp: true,
      pendingAuthentication: true
    });
  }

  function logOutCallback(): void {
    log('logout');
    Storage.clear().then(() => {
      log("Storage cleared. Resetting states...");
      setState(initialState);
    });
  }

  function accountRemoveEffect() {
    let canceled = false;
    removeAccountAsync().then();
    return () => {
      canceled = true;
    }
    async function removeAccountAsync() {
      if (!pendingRemove) {
        log('removeAccountAsync, !pendingRemove, return');
        return;
      }
      try {
        log('removing...');
        setState({
          ...state,
          isAuthenticating: true,
        });
        const storedToken = await Storage.get({ key: 'userToken' });
        if(!storedToken.value) return;
        const { targetUsername, password } = state;
        await deleteUser(storedToken.value, targetUsername!, password);

        logOutCallback();
        present({message: 'Goodbye.', duration: 3500, animated: true, position: 'bottom'});
      } catch (error:any) {
        if (canceled) return;
        log('removal failed');
        if (!error.response) {
          setState(initialState);
          present({message: 'Please check your internet connection...', duration: 3500, animated: true, position: 'bottom'});
        }
        else
          setState({
            ...state,
            authenticationError: error,
            pendingRemove: false,
            isAuthenticating: false,
          });
      }
    }
  }

  function authenticationEffect() {
    let canceled = false;
    authenticate().then();
    return () => {
      canceled = true;
    }

    async function authenticate() {
      const storedToken = await Storage.get({ key: 'userToken' });
      const storedUserId = await Storage.get({ key: 'currentUserId' });
      const savedUsername = await Storage.get({ key: 'username' });
      const savedReputationScore = await Storage.get({ key: 'reputationScore' });
      if (storedToken.value && storedToken.value !== ''){
        setState({
          isAuthenticated: true,
          isAuthenticating: false,
          authenticationError: null,
          pendingAuthentication: false,
          token: storedToken.value,
          currentUserId: storedUserId.value!,
          username: savedUsername.value!,
          reputationScore: +savedReputationScore.value!,
          invitation: ''
        });
        return;
      }
      if (!pendingAuthentication || isAuthenticated) {
        log('authenticated, !pendingAuthentication, return');
        return;
      }
      try {
        log('authenticate...');
        setState({
          ...state,
          isAuthenticating: true,
        });
        const { username, password, invitation } = state;

        let user: UserProps;
        if(state.pendingSignUp)
            user = await signupApi(username, password, invitation);
        else
            user = await loginApi(username, password);
        if (canceled) {
          return;
        }
        log('authenticate succeeded');
        setState({
          ...state,
          token: user.token!,
          currentUserId: String(user.id),
          username,
          reputationScore: user.reputationScore,
          pendingAuthentication: false,
          pendingSignUp: false,
          isAuthenticated: true,
          isAuthenticating: false,
          invitation: ''
        });
        await Storage.set({
          key: 'userToken',
          value: user.token!
        });
        await Storage.set({
          key: 'currentUserId',
          value: String(user.id)
        });
        await Storage.set({
          key: 'reputationScore',
          value: String(user.reputationScore)
        });
      } catch (error:any) {
        if (canceled) {
          return;
        }
        log('authenticate failed');
        if (!error.response) {
          setState(initialState);
          present({message: 'Please check your internet connection...', duration: 3500, animated: true, position: 'bottom'});
        }
        else
          setState({
            ...state,
            authenticationError: error,
            pendingAuthentication: false,
            pendingSignUp: false,
            isAuthenticating: false,
          });
      }
    }
  }
};

import {getLogger} from "../core";
import React, {useContext, useEffect, useState} from "react";
import {RouteComponentProps} from "react-router";

import {AlertContext} from "../core/AlertProvider";
import Alert from '../components/Alert';
import {
    IonButton, IonButtons, IonCol, IonContent, IonFooter, IonHeader, IonItem, IonLabel, IonList, IonLoading, IonPage,
    IonRow, IonSelect, IonSelectOption, IonText, IonTitle, IonToolbar
} from "@ionic/react";
import {AlertProps} from "../core/AlertProps";
import {getUserAlerts} from "../core/alertApi";
import {AuthContext} from "../auth";
const log = getLogger('UserAlertsAdmin');

interface UserAlertsPageProps extends RouteComponentProps<{
    id?: string;
}> {}

const UserAlertsAdmin: React.FC<UserAlertsPageProps> = ({ history, match }) => {
    const [filterAlerts, setFilterAlerts] = useState('alerts');
    const [userAlerts, setUserAlerts] = useState<AlertProps[]>([]);
    const [fetchingError, setFetchingError] = useState(false);
    const [fetching, setFetching] = useState(false);
    const { token } = useContext(AuthContext);
    const { c_lat, c_lng } = useContext(AlertContext);
    useEffect(() => {
        fetchUserAlertsAdmin().then();
        async function fetchUserAlertsAdmin() {
            try {
                log('fetchUserAlertsAdmin started');
                setFetching(true);
                const alerts_response = await getUserAlerts(token,match.params.id!);
                setUserAlerts(alerts_response.alerts);
                log('fetchUserAlertsAdmin succeeded');
                setFetching(false);
                setFetchingError(false);
            } catch (error: any) {
                log('fetchUserAlertsAdmin failed');
                setFetching(false);
                setFetchingError(true);
            }
        }
    }, []);
    log("Render MyAlerts")
    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonTitle color="success">User alerts/solutions</IonTitle>
                    <IonButtons slot="end">
                        <IonButton onClick={() => history.goBack()}>
                            Back
                        </IonButton>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <IonContent fullscreen className="ion-padding">
                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large">GreenDefender</IonTitle>
                    </IonToolbar>
                </IonHeader>
                <p className="ion-text-md-wrap">
                    <IonText className="ion-margin-start">Number of alerts: {userAlerts?.length}</IonText> <br/>
                    <IonText className="ion-margin-start">Number of alerts with solutions: {userAlerts?.filter(a => a.isSolved).length}</IonText>
                </p>

                <IonLoading isOpen={fetching} message="Fetching user alerts ..." />
                <IonItem className="ion-margin-bottom">
                    <IonLabel> Show </IonLabel>
                    <IonSelect value={filterAlerts} okText="Okay" cancelText="Cancel" onIonChange={e => setFilterAlerts!(e.detail.value)}>
                        <IonSelectOption value={'alerts'}>alerts</IonSelectOption>
                        <IonSelectOption value={'solutions'}>solutions</IonSelectOption>
                    </IonSelect>
                </IonItem>
                {userAlerts && (
                    <IonList>
                        {userAlerts
                            .filter((a) => {
                                if(filterAlerts === 'solutions') return a.isSolved;
                                return a.userId === match.params.id!;
                            })
                            .map((alert) =>
                                <Alert   key={alert.id} id={alert.id}
                                       title={alert.title}
                                 description={alert.description}
                                    category={alert.category}
                               reportedPhoto={alert.reportedPhoto}
                                 solverPhoto={alert.solverPhoto}
                                    isSolved={alert.isSolved}
                                    location={alert.location}
                                      userId={alert.userId}
                                    solverId={alert.solverId}
                                  alertScore={alert.alertScore}
                               solutionScore={alert.solutionScore}
                           solverDescription={alert.solverDescription}
                             currentLocation={{latitude: c_lat, longitude: c_lng}}
                                    selected={filterAlerts}
                                      onEdit={() => null}  />)}
                    </IonList>
                )}
                {fetchingError && (
                    <div><p>{'No server connection.' || 'Failed to fetch user alerts'}</p></div>
                )}

                <IonRow className="ion-align-items-center">
                    <IonCol className="ion-text-center">
                        <IonFooter className="ion-padding">
                            <p> </p>
                            <i>Earth provides enough to satisfy every man’s needs, but not every man’s greed.</i>
                            <p> </p>
                        </IonFooter>
                    </IonCol>
                </IonRow>
            </IonContent>
        </IonPage>
    );
};

export default UserAlertsAdmin;
import React from "react";
import {GoogleMap, Marker, withGoogleMap, withScriptjs} from 'react-google-maps';
import {compose, withProps} from 'recompose';

interface MyMapProps {
    c_lat: number;  // central marker
    c_lng: number;
    zoom: number;
    coordinates: [number, number][];
    onMapClick: (e: any) => void,
    onMarkerClick: (e: any) => void,
    centerUser: true
}

export const MyMap =
    compose<MyMapProps, any>(
        withProps({
            googleMapURL:
                `https://maps.googleapis.com/maps/api/js?key=AIzaSyAqdC03IqlcoEBTGbEoCxMdX_AjzLPKjMM&v=3.exp&libraries=geometry,drawing,places`,
            loadingElement: <div style={{ height: `100%` }} />,
            containerElement: <div style={{ height: `60%` }} />,
            mapElement: <div style={{ height: `100%` }} />
        }),
        withScriptjs,
        withGoogleMap
    )
    (props => (
        <GoogleMap
            defaultZoom={props.zoom}
            defaultCenter={(props.centerUser || !props.coordinates) ? { lat: props.c_lat, lng: props.c_lng } : { lat: props.coordinates[0][0], lng: props.coordinates[0][1] }}
            onClick={props.onMapClick}
            options={{gestureHandling: "cooperative"}}
        >
            <Marker
                key="me"
                position={{ lat: props.c_lat, lng: props.c_lng }}
                //onClick={props.onMarkerClick}
                //icon={"https://maps.google.com/mapfiles/kml/pal3/icon28.png"}
                icon={"assets/icon/myLocation2.png"}
            />
            {props.coordinates && props.coordinates.map(([lat,lng], idx) =>
                <Marker
                    key={idx}
                    position={{ lat: lat, lng: lng }}
                    onClick={props.onMarkerClick}
                /> )
            }
        </GoogleMap>
    ))

export function getDistanceFromLatLonInKm(lat1: number, lon1: number, lat2: number, lon2: number) {
    let R = 6371; // Radius of the earth in km
    let dLat = deg2rad(lat2-lat1);  // deg2rad below
    let dLon = deg2rad(lon2-lon1);
    let a =
        Math.sin(dLat/2) * Math.sin(dLat/2) +
        Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) *
        Math.sin(dLon/2) * Math.sin(dLon/2)
    ;
    let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
    // Distance in km
    return R * c;
}

function deg2rad(deg: number) {
    return deg * (Math.PI/180)
}

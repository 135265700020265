import {Camera, CameraResultType, Photo as CameraPhoto} from '@capacitor/camera';
import {Directory as FilesystemDirectory} from '@capacitor/filesystem'
import {useEffect, useState} from 'react';
import {base64FromPath, useFilesystem} from '@capacitor-community/react-hooks/filesystem';
import { useStorage } from '@capacitor-community/react-hooks/storage';

export interface Photo {
    filepath: string;
    webviewPath?: string;
    data?: string;
}

const PHOTO_STORAGE = 'photos';

export function usePhotoCamera() {
    const { get, set } = useStorage();
    const [photos, setPhotos] = useState<Photo[]>([]);
    const { deleteFile, readFile, writeFile } = useFilesystem();

    const takePhoto = async () => {
        let camPhoto = undefined;
        try{
            camPhoto = await Camera.getPhoto({
                resultType: CameraResultType.Uri,
                //source: CameraSource.Camera,
                quality: 100
            });
        }catch (e : any){
            return;
        }

        const fileName = new Date().getTime() + '.jpeg';
        const savedFileImage = await savePicture(camPhoto!, fileName);
        const newPhotos = [savedFileImage, ...photos];
        setPhotos(newPhotos);
        // set(PHOTO_STORAGE, JSON.stringify(newPhotos)).then().catch(e => {console.log(e);
        //     set(PHOTO_STORAGE,JSON.stringify([]));});
        return {filepath: savedFileImage.filepath, data: savedFileImage.data};
    };


    const savePicture = async (photo: CameraPhoto, fileName: string): Promise<Photo> => {
        const base64Data = await base64FromPath(photo.webPath!);
        let result = await resizeImage(base64Data);
        // @ts-ignore
        const resizedBase64Data: string = result.resized;
        await writeFile({
            path: fileName,
            data: resizedBase64Data,
            directory: FilesystemDirectory.Data
        });

        return {
            filepath: fileName,
            webviewPath: photo.webPath,
            data: resizedBase64Data
        };
    };

    const deletePhoto = async (photo: Photo) => {
        if(photo && photo.webviewPath){
            const newPhotos = photos.filter(p => p.filepath !== photo.filepath);
            await set(PHOTO_STORAGE, JSON.stringify(newPhotos));
            const filename = photo.filepath.substr(photo.filepath.lastIndexOf('/') + 1);
            await deleteFile({
                path: filename,
                directory: FilesystemDirectory.Data
            });
            setPhotos(newPhotos);
        }
    };

    useEffect(() => {
        const loadSaved = async () => {
            const photosString = await get(PHOTO_STORAGE);
            const photos = (photosString ? JSON.parse(photosString) : []) as Photo[];
            for (let photo of photos) {
                const file = await readFile({
                    path: photo.filepath,
                    directory: FilesystemDirectory.Data
                });
                photo.webviewPath = `data:image/jpeg;base64,${file.data}`;
            }
            setPhotos(photos);
        };
        loadSaved().then();
    }, [get, readFile]);

    function resizeImage(base64Photo: string) {
        return new Promise (function (resolved, rejected) {
            let i = new Image()
            i.onload = function(){
                let resized = '';
                let canvas: HTMLCanvasElement = document.createElement("canvas");
                let picaWidth;
                let picaHeight;

                // Resize image to max width or height of 500 pixels
                let maxWH = 500;
                if (i.width > i.height && i.width > maxWH) {
                    picaWidth = maxWH;
                    picaHeight = maxWH * i.height / i.width;
                } else if (i.width < i.height && i.height > maxWH) {
                    picaWidth = maxWH * i.width / i.height;
                    picaHeight = maxWH;
                }
                else{
                    picaWidth = i.width;
                    picaHeight = i.height;
                }
                canvas.width = picaWidth;
                canvas.height = picaHeight;

                let snapshotCtx = canvas.getContext("2d");
                if (snapshotCtx){
                    snapshotCtx.drawImage(i, 0, 0, picaWidth, picaHeight);
                    resized = canvas.toDataURL('image/jpeg', 0.8);
                    resolved({resized});
                }
                rejected('snapshotCtx is null');
            };
            i.src = base64Photo
        })
    }

    return {
        photos,
        takePhoto,
        deletePhoto,
    };
}


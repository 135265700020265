import {
    IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonList, IonItem, IonFooter, IonLabel, IonAlert, IonGrid,
    IonRow, IonCol, IonText, IonImg, IonThumbnail, IonAvatar, IonIcon, IonItemDivider,
} from '@ionic/react';
import React, { useState } from 'react';
import { RouteComponentProps } from 'react-router';
import './Home.css';
import {IonButton, IonButtons, } from '@ionic/react';
import {arrowForward} from "ionicons/icons";

const RulesPage: React.FC<RouteComponentProps> = ({ history }) => {

    return (
    <IonPage>
        <IonHeader>
            <IonToolbar>
                  <IonTitle color="success"> GreenDefender </IonTitle>
                    <IonButtons slot="end">
                        <IonButton onClick={() => history.goBack()}> Back </IonButton>
                    </IonButtons>
            </IonToolbar>
        </IonHeader>
        <IonContent fullscreen className="ion-padding">
            <IonHeader collapse="condense">
              <IonToolbar>
                <IonTitle size="large">GreenDefender</IonTitle>
              </IonToolbar>
            </IonHeader>

            <div className="container ion-padding">
                <IonAvatar className="avatar">
                    <IonImg src={"/assets/icon/icon.png"} />
                </IonAvatar>
                <p>  &nbsp; </p>
                  <strong>The <i>GreenDefender</i> rules</strong>
                  <p> Like any other thriving community out there, we also have some rules that you should know about :)</p>
                <p>  &nbsp; </p>
            </div>
            <p className="ion-margin-start ion-padding-bottom">
            First of all, if you want to learn more about <b><i>why</i></b> GreenDefender exists and
              the mission of our community, you can always start with <a target="_blank" rel="noopener noreferrer" href="https://en.wikipedia.org/wiki/Environmental_issues">this Wikipedia article</a>.
            </p>

            <IonList>
                <IonItem>
                    <IonIcon slot="start" icon={arrowForward} />
                    <IonText color="danger">
                        A new member can join the community either by using an invitation code generated by an existing member (who has a positive reputation score),
                        or by contacting the administrator (<i>GreenAdmin</i> at spurge@holy.wtf) for an invitation code.
                    </IonText>
                </IonItem>
                <IonItemDivider/>

                <IonItem>
                    <IonIcon slot="start" icon={arrowForward} />
                    <IonText color="danger">
                        The application uses the GPS location of the user to show all the alerts as far as 50 km, and for locating any alert he/she posts.
                    </IonText>
                </IonItem>
                <IonItemDivider/>

                <IonItem>
                    <IonIcon slot="start" icon={arrowForward} />
                    <IonText color="danger">
                        The Leaderboard page presents the top 100 users, sorted descending by the reputation score. Every member in this list is advantaged.
                    </IonText>
                </IonItem>
                <IonItemDivider/>

                <IonItem>
                    <IonIcon slot="start" icon={arrowForward} />
                    <IonText color="danger">
                        The alerts with negative score lower than −10 are not shown on map.
                    </IonText>
                </IonItem>
                <IonItemDivider/>

                <IonItem>
                    <IonIcon slot="start" icon={arrowForward} />
                    <IonText color="danger">
                        The alert or solution poster is anonymous for the other users. The evaluation of the work should not be influenced by who the worker is.
                    </IonText>
                </IonItem>
                <IonItemDivider/>

                <IonItem>
                    <IonIcon slot="start" icon={arrowForward} />
                    <IonText color="danger">
                        The value of each up-vote/down-vote is directly proportional with the current reputation score of the voter.
                    </IonText>
                </IonItem>
                <IonItemDivider/>

                <IonItem>
                    <IonIcon slot="start" icon={arrowForward} />
                    <IonText color="danger">
                        The up-vote/down-vote for an alert/solution cannot be undone or redone.
                    </IonText>
                </IonItem>
                <IonItemDivider/>

                <IonItem>
                    <IonIcon slot="start" icon={arrowForward} />
                    <IonText color="danger">
                        For an alert, an user can post an <i>unlocked</i> or <i>locked</i> solution.
                        The <i>unlocked</i> solution can always be replaced by another member and <b>cannot</b> be voted.
                        The <i>locked</i> solution cannot be replaced and it <b>can</b> be voted. However, once
                        it reaches a negative score (due to voting), it becomes <i>unlocked</i>.
                    </IonText>
                </IonItem>
                <IonItemDivider/>

                <IonItem>
                    <IonIcon slot="start" icon={arrowForward} />
                    <IonText color="danger">
                        The users with reputation scores lower than −10 cannot vote and cannot post a <i>locked</i> solution.
                    </IonText>
                </IonItem>
                <IonItemDivider/>

                <IonItem>
                    <IonIcon slot="start" icon={arrowForward} />
                    <IonText color="danger">
                        An alert cannot be up-voted/down-voted while it has a <i>locked</i> solution. This is when the solution has to be voted.
                    </IonText>
                </IonItem>
                <IonItemDivider/>

                <IonItem>
                    <IonIcon slot="start" icon={arrowForward} />
                    <IonText color="danger">
                        To add a solution to an alert, the user has to up-vote it first.
                    </IonText>
                </IonItem>
                <IonItemDivider/>

                <IonItem>
                    <IonIcon slot="start" icon={arrowForward} />
                    <IonText color="danger">
                        The alerts with negative score disappear after a month.
                    </IonText>
                </IonItem>
                <IonItemDivider/>

                <IonItem>
                    <IonIcon slot="start" icon={arrowForward} />
                    <IonText color="danger">
                        The alerts with positive solution score disappear after an year.
                    </IonText>
                </IonItem>
                <IonItemDivider/>

                <IonItem>
                    <IonIcon slot="start" icon={arrowForward} />
                    <IonText color="danger">
                        The GreenAdmin can always remove an user with negative reputation score.
                        Then, the reputation score of the one who invited that user will be lowered.
                    </IonText>
                </IonItem>
            </IonList>

            <IonRow className="ion-align-items-center">
                <IonCol className="ion-text-center">
                    <IonFooter className="ion-padding">
                        <p> </p>
                        <i>Earth provides enough to satisfy every man’s needs, but not every man’s greed.</i>
                        <p> </p>
                    </IonFooter>
                </IonCol>
            </IonRow>
        </IonContent>
    </IonPage>
    );
};

export default RulesPage;

import {getLogger, round} from "../core";
import React, {useContext, useEffect, useState} from "react";
import {RouteComponentProps} from "react-router";
import {
    IonButton,
    IonButtons,
    IonCol,
    IonContent,
    IonFooter,
    IonHeader,
    IonItem,
    IonList,
    IonLoading,
    IonPage, IonRow,
    IonText,
    IonTitle,
    IonToolbar, useIonAlert
} from "@ionic/react";
import {getAllUsersAdmin, deleteUser} from "../auth/authApi";
import {UserProps} from "../auth/UserProps";
import {AuthContext} from "../auth";
const log = getLogger('AdminPanel');

const AdminPanel: React.FC<RouteComponentProps> = ({ history }) => {
    const { token } = useContext(AuthContext);
    const [allUsers, setAllUsers] = useState<UserProps[]>([]);
    const [fetchingError, setFetchingError] = useState(false);
    const [noOfAlerts, setNoOfAlerts] = useState(0);
    const [present] = useIonAlert();
    useEffect(() => {
        let cancel = false;
        fetchAllUsersAdmin().then();
        async function fetchAllUsersAdmin() {
            try {
                log('fetchAllUsersAdmin started');
                const users_response = await getAllUsersAdmin(token);
                if (cancel) return;
                setNoOfAlerts(users_response.noOfAlerts || 0);
                setAllUsers(users_response.users);
                log('fetchAllUsersAdmin succeeded');
                setFetchingError(false);
            } catch (error: any) {
                log('fetchAllUsersAdmin failed');
                setFetchingError(true);
            }
        }
        return () => {
            cancel = true;
        }
    }, []);

    const handleRemoveUser = (username: string) => {
        log('handleRemoveUser');
        present({
            header: 'Confirm account deletion',
            message: 'All the progress of this user will be lost (reputation score, alerts and solutions).',
            cssClass:'logout-button',
            buttons: [
                'Cancel',
                { text: 'REMOVE USER',
                    handler: () => handleRemoveUserAsync(username).then()
                },
            ]
        });
        async function handleRemoveUserAsync(username: string) {
            try {
                log('removing user...');
                await deleteUser(token,username,'ThisUserWi11BeYEETED!');
                log('user ' + username + ' removed');
                const localUsers = [...(allUsers || [])];
                const index = localUsers.findIndex(u => u.username === username);
                if (index !== -1) localUsers.splice(index, 1);
                setAllUsers(localUsers);
            } catch (error: any) {
                log('user removal failed');
                if (!error.response) {
                    present({
                        message: 'Please check your internet connection...',
                        buttons: ['Ok']
                    });
                }
                else {
                    present({
                        message: error.response.data.message || 'User removal failed',
                        buttons: ['Ok']
                    });
                }
            }
        }
    }

    log("Render")
    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonTitle color="success">Admin panel</IonTitle>
                    <IonButtons slot="end">
                        <IonButton onClick={() => history.goBack()}>
                            Back
                        </IonButton>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>

            <IonContent fullscreen className="ion-padding">
                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large">GreenDefender</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <div className="container ion-padding">
                    <strong><IonText color="success">GreenAdmin</IonText></strong>
                </div>
                <p className="ion-text-md-wrap ion-padding-bottom">
                    <IonText className="ion-margin-start">Total number of alerts: {noOfAlerts}</IonText> <br/> <br/>
                    <IonText className="ion-margin-start">Total number of users: {allUsers?.length}</IonText>
                </p>

                <IonLoading isOpen={!allUsers} message="Fetching all users ..." />

                {allUsers && (
                    <IonList>
                        {allUsers
                            .sort((a,b) => {        // keep them sorted
                                if(a.reputationScore > b.reputationScore)
                                    return 1;
                                if(a.reputationScore < b.reputationScore)
                                    return -1;
                                return 0;
                            })
                            .map((user) =>
                                    <IonItem key={user.id} className="ion-text-center ion-margin-bottom ion-margin-end" color={"positive"}>
                                        <IonCol><b>{user.username}</b></IonCol>
                                        <IonCol>rep: <b>{round(user.reputationScore,5)}</b></IonCol>
                                        <IonCol>
                                            <IonButton onClick={() => history.push(`/userAlertsAdmin/${user.id}`)}>
                                                Alerts
                                            </IonButton>
                                        </IonCol>
                                        <IonCol>
                                            <IonButton onClick={() => handleRemoveUser(user.username)} color="danger">
                                                Remove
                                            </IonButton>
                                        </IonCol>
                                    </IonItem>
                                )
                        }
                    </IonList>
                )}
                {fetchingError && (
                    <div><p>{'No server connection.' || 'Failed to fetch users'}</p></div>
                )}

                <IonRow className="ion-align-items-center">
                    <IonCol className="ion-text-center">
                        <IonFooter className="ion-padding">
                            <p> </p>
                            <i>Earth provides enough to satisfy every man’s needs, but not every man’s greed.</i>
                            <p> </p>
                        </IonFooter>
                    </IonCol>
                </IonRow>
            </IonContent>
        </IonPage>
    );
};

export default AdminPanel;
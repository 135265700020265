import React, { useContext, useState } from 'react';
import {
    IonButton,
    IonButtons,
    IonLabel,
    IonItem,
    IonContent,
    IonHeader,
    IonInput,
    IonPage,
    IonTitle,
    IonToolbar,
    IonLoading,
    IonCol,
    IonImg,
    IonIcon,
    IonActionSheet,
    IonTextarea,
    IonSelect,
    IonSelectOption,
    useIonAlert,
    IonText
} from '@ionic/react';
import { Clipboard } from '@capacitor/clipboard';
import {getLogger, round} from '../core';
import { RouteComponentProps } from 'react-router';
import {MyMap} from "../components/MyMap";
import {usePhotoCamera} from "../core/usePhotoCamera";
import {AlertProps} from "../core/AlertProps";
import {camera, trash, close, copy} from "ionicons/icons";
import {AlertContext} from "../core/AlertProvider";
import {AuthContext} from "../auth";

const log = getLogger('AlertAdd');

interface AlertEditProps extends RouteComponentProps<{
    id?: string;
}> {}

const AlertAdd: React.FC<AlertEditProps> = ({ history }) => {
    const {currentUserId} = useContext(AuthContext);
    const [present] = useIonAlert();
    const { saving, savingError, saveAlert, deleting, deletingError, c_lat: lat, c_lng: lng, maxDistance} = useContext(AlertContext);
    const { takePhoto, deletePhoto } = usePhotoCamera();
    const [photoToDelete, setPhotoToDelete] = useState<string>();
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [category, setCategory] = useState('');
    const [reportedPhoto, setReportedPhoto] = useState<string|undefined>(undefined);
    const [selectedLocation, setSelectedLocation] = useState<{latitude:number|undefined, longitude:number|undefined}>({latitude:0, longitude:0});
    const handleSave = () => {
        if(title === '' || description === '' || category === '' || reportedPhoto === ''){
            present({
                header: 'Incomplete alert',
                message: 'All fields must be filled: title, description, category and reported photo.',
                buttons: [{ text: 'Ok'}]
            })
            return;
        }
        const newAlert: AlertProps =
            { title, description, reportedPhoto, category, isSolved:false,
                location: { latitude: selectedLocation.latitude===0 ? lat : selectedLocation.latitude,
                           longitude: selectedLocation.longitude===0 ? lng : selectedLocation.longitude},
                userId: currentUserId, alertScore: 0 };
        saveAlert && saveAlert(newAlert).then(() => history.goBack());
    };

    const handleCancel = () => {
        if(title !== '' || description !== '' || category !== '' || reportedPhoto){
            present({
                header: 'Are you sure?',
                message: 'The information you entered will be lost.',
                buttons: [
                    'No',
                    { text: 'Yes',
                        handler: () => {
                            history.goBack();
                        }
                    },
                ]
            })
        }
        else history.goBack();
    };

    log('render');
    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonTitle>Create alert</IonTitle>
                    <IonButtons slot="end">
                        <IonButton onClick={handleSave} color="success">
                            Save
                        </IonButton>
                        <IonButton onClick={handleCancel}>
                            Cancel
                        </IonButton>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
                <IonContent className="ion-padding">
                    <IonItem>
                        <IonLabel color="danger">Title:</IonLabel>
                        <IonInput placeholder="Enter title" value={title} onIonChange={e => setTitle(e.detail.value || '')}/>
                    </IonItem>
                    <IonItem>
                        <IonLabel color="danger">Description:</IonLabel>
                        <IonTextarea placeholder="Describe the problem" value={description} onIonChange={e => setDescription(e.detail.value || '')} />
                    </IonItem>

                    <IonItem>
                        <IonLabel color="danger">Reported photo:</IonLabel>
                        {reportedPhoto &&<IonCol size="4">
                            <IonImg onClick={() => {
                                setPhotoToDelete(reportedPhoto);
                                present({
                                    header: 'Reported photo',
                                    message: `<img src="${reportedPhoto}" alt="r-img" >`,
                                });
                            }}
                                    src={reportedPhoto}/>
                        </IonCol>}
                        <IonCol>
                            <IonButton onClick={() => takePhoto().then(res => res? setReportedPhoto(res.data) : undefined)}>
                                <IonIcon icon={camera}/>
                            </IonButton>
                        </IonCol>
                    </IonItem>

                    <IonActionSheet
                        isOpen={!!photoToDelete}
                        buttons={[{
                            text: 'Delete',
                            role: 'destructive',
                            icon: trash,
                            handler: () => {
                                if (photoToDelete) {
                                    deletePhoto({filepath: '', data: photoToDelete}).then(_ => {
                                        if(reportedPhoto && photoToDelete === reportedPhoto)
                                            setReportedPhoto(undefined);
                                        setPhotoToDelete(undefined);
                                    });
                                }
                            }
                        }, {
                            text: 'Cancel',
                            icon: close,
                            role: 'cancel'
                        }]}
                        onDidDismiss={() => setPhotoToDelete(undefined)}
                    />

                    <IonItem>
                        <IonLabel color="danger">Category:</IonLabel>
                        <IonSelect interface="popover" value={category} onIonChange={e => setCategory(e.detail.value || '')}>
                            <IonSelectOption value="air pollution"> air pollution </IonSelectOption>
                            <IonSelectOption value="deforestation"> deforestation </IonSelectOption>
                            <IonSelectOption value="household waste"> household waste </IonSelectOption>
                            <IonSelectOption value="industrial waste"> industrial waste </IonSelectOption>
                            <IonSelectOption value="landfill"> landfill </IonSelectOption>
                            <IonSelectOption value="littering"> littering </IonSelectOption>
                            <IonSelectOption value="logging"> logging </IonSelectOption>
                            <IonSelectOption value="poaching"> poaching </IonSelectOption>
                            <IonSelectOption value="soil pollution"> soil pollution </IonSelectOption>
                            <IonSelectOption value="water pollution"> water pollution </IonSelectOption>
                        </IonSelect>
                    </IonItem>

                    <IonItem>
                        <IonLabel color="danger">{selectedLocation.latitude!==0 ? '' : 'YOUR'} GPS Location:</IonLabel>
                    </IonItem>
                    <IonItem>
                        <IonCol>
                            lat {selectedLocation.latitude!==0 ? round(selectedLocation.latitude!,10) : round(lat!,10)}
                        </IonCol>
                        <IonCol>
                            long {selectedLocation.longitude!=0 ? round(selectedLocation.longitude!,10) : round(lng!,10)}
                        </IonCol>
                        <IonCol>
                            <IonButton size="small" color="medium" shape="round"
                                       onClick={()=>Clipboard.write({string: lat + ', ' + lng})}>
                                <IonIcon slot="icon-only" icon={copy} />
                            </IonButton>
                        </IonCol>
                    </IonItem>

                    <IonItem>
                        <IonText color="medium" className="ion-margin-bottom ion-margin-top">The location of the alert should now be the <b>same</b> as your location.
                            However, if you really need to change it, you can tap on the map.</IonText>
                    </IonItem>

                    {lat && lng &&
                    <MyMap
                        c_lat={lat}
                        c_lng={lng}
                        coordinates={[[selectedLocation.latitude,selectedLocation.longitude]]}
                        centerUser={true}
                        zoom={maxDistance===10 ? 12 : (maxDistance===5 ? 13 : (maxDistance===1 ? 15 : (maxDistance===20 ? 11 : 9)))}
                        onMapClick={(e: google.maps.MapMouseEvent) => {  log('onMapTap');
                            if(selectedLocation.latitude === 0 && selectedLocation.longitude === 0)
                                present({
                                    header: 'Default location change warning!',
                                    message: 'You are about to set a location that\'s not yours for the new alert. ' +
                                        'Make sure you choose the correct location, so the nearby members can find & solve the problem. ' +
                                        'Otherwise, your alert & reputation score will be lowered.',
                                    buttons: [
                                        'Cancel',
                                        { text: 'Change location',
                                            handler: () => {
                                                setSelectedLocation({latitude: e.latLng!.toJSON().lat, longitude: e.latLng!.toJSON().lng});
                                            }
                                        },
                                    ]
                                })
                            else setSelectedLocation({latitude: e.latLng!.toJSON().lat, longitude: e.latLng!.toJSON().lng});
                            //console.log({latitude: e.latLng!.toJSON().lat, longitude: e.latLng!.toJSON().lng});
                            }
                        }
                    /> }

                    <IonLoading isOpen={saving} />
                    {savingError && (
                        <div>{savingError.message || 'Failed to save alert'}</div>
                    )}
                    <IonLoading isOpen={deleting} />
                    {deletingError && (
                        <div>{deletingError.message || 'Failed to delete alert'}</div>
                    )}
                </IonContent>
        </IonPage>
    );

}

export default AlertAdd;
import React from "react";
import { Redirect, Route } from 'react-router-dom';
import { IonApp, IonRouterOutlet } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import Home from './pages/Home';
import AlertAdd from "./pages/AlertAdd";
import AlertEdit from "./pages/AlertEdit";
import MyAlerts from "./pages/MyAlerts";
import AlertVoteSolve from "./pages/AlertVoteSolve";
import WelcomePage from "./pages/WelcomePage";
import RulesPage from "./pages/RulesPage";
import Leaderboard from "./pages/Leaderboard";
import ProfilePage from "./pages/ProfilePage";
import AdminPanel from "./pages/AdminPanel";
import UserAlertsAdmin from "./pages/UserAlertsAdmin";
import {AlertProvider} from "./core/AlertProvider";
import {AuthProvider, PrivateRoute, Login, SignUp} from './auth';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';

const App: React.FC = () => (
  <IonApp>
    <IonReactRouter>
      <IonRouterOutlet>
        <AuthProvider>
          <Route path="/login" component={Login} exact={true}/>
          <Route path="/signup" component={SignUp} exact={true}/>
          <Route path="/rules" component={RulesPage} exact={true}/>
          <Route path="/leaderboard" component={Leaderboard} exact={true}/>
          <AlertProvider>
            <Route path="/welcome" component={WelcomePage} exact={true}/>
            <PrivateRoute path="/profile" component={ProfilePage} exact={true}/>
            <PrivateRoute path="/adminPanel" component={AdminPanel} exact={true}/>
            <PrivateRoute path="/home" component={Home} exact={true}/>
            <PrivateRoute path="/alertAdd" component={AlertAdd} exact={true}/>
            <PrivateRoute path="/alertEdit/:id" component={AlertEdit} exact={true}/>
            <PrivateRoute path="/alertVoteSolve/:id" component={AlertVoteSolve} exact={true}/>
            <PrivateRoute path="/myAlerts" component={MyAlerts} exact={true}/>
            <PrivateRoute path="/userAlertsAdmin/:id" component={UserAlertsAdmin} exact={true}/>
          </AlertProvider>
          <Route exact path="/" render={() => <Redirect to="/home"/>}/>
        </AuthProvider>
      </IonRouterOutlet>
    </IonReactRouter>
  </IonApp>
);

export default App;

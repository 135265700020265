import {getLogger} from "../core";
import React, {useContext, useState} from "react";
import {RouteComponentProps} from "react-router";

import {AlertContext} from "../core/AlertProvider";
import Alert from '../components/Alert';
import {
    IonButton, IonButtons, IonCol, IonContent, IonFooter, IonHeader, IonItem, IonLabel, IonList, IonLoading, IonPage,
    IonRow, IonSelect, IonSelectOption, IonText, IonTitle, IonToolbar
} from "@ionic/react";
import {AuthContext} from "../auth";
const log = getLogger('MyAlerts');

const MyAlerts: React.FC<RouteComponentProps> = ({ history }) => {
    const [filterAlerts, setFilterAlerts] = useState('alerts');
    const { currentUserId } = useContext(AuthContext);
    const { userAlerts, userFetching, userFetchingError, c_lat, c_lng} = useContext(AlertContext);
    log("Render MyAlerts")
    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonTitle color="success">My alerts/solutions</IonTitle>
                    <IonButtons slot="end">
                        <IonButton onClick={() => history.push('/home')}>
                            Home
                        </IonButton>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <IonContent fullscreen className="ion-padding">
                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large">GreenDefender</IonTitle>
                    </IonToolbar>
                </IonHeader>
                <p className="ion-text-md-wrap">
                    <IonText className="ion-margin-start">Number of alerts: {userAlerts?.filter(a => a.userId===currentUserId).length}</IonText> <br/>
                    <IonText className="ion-margin-start">Number of alerts with solutions: {userAlerts?.filter(a => a.isSolved && a.solverId === currentUserId).length}</IonText>
                </p>

                <IonLoading isOpen={userFetching} message="Fetching your alerts ..." />
                <IonItem className="ion-margin-bottom">
                    <IonLabel> Show </IonLabel>
                    <IonSelect value={filterAlerts} okText="Okay" cancelText="Cancel" onIonChange={e => setFilterAlerts!(e.detail.value)}>
                        <IonSelectOption value={'alerts'}>alerts</IonSelectOption>
                        <IonSelectOption value={'solutions'}>solutions</IonSelectOption>
                    </IonSelect>
                </IonItem>
                {userAlerts && (
                    <IonList>
                        {userAlerts
                            .filter((a) => {
                                if(filterAlerts === 'solutions') return a.isSolved && a.solverId === currentUserId;
                                return a.userId === currentUserId;
                            })
                            .map((alert) =>
                                <Alert   key={alert.id} id={alert.id}
                                       title={alert.title}
                                 description={alert.description}
                                    category={alert.category}
                               reportedPhoto={alert.reportedPhoto}
                                 solverPhoto={alert.solverPhoto}
                                    isSolved={alert.isSolved}
                                    location={alert.location}
                                      userId={alert.userId}
                                    solverId={alert.solverId}
                                  alertScore={alert.alertScore}
                               solutionScore={alert.solutionScore}
                           solverDescription={alert.solverDescription}
                             currentLocation={{latitude: c_lat, longitude: c_lng}}
                                    selected={filterAlerts}
                                      onEdit={id => history.push(`/alertEdit/${id}`)}  />)}
                    </IonList>
                )}
                {userFetchingError && (
                    <div><p>{'Failed to fetch your alerts. Check your internet connection.'}</p></div>
                )}

                <IonRow className="ion-align-items-center">
                    <IonCol className="ion-text-center">
                        <IonFooter className="ion-padding">
                            <p> </p>
                            <i>Earth provides enough to satisfy every man’s needs, but not every man’s greed.</i>
                            <p> </p>
                        </IonFooter>
                    </IonCol>
                </IonRow>
            </IonContent>
        </IonPage>
    );
};

export default MyAlerts;